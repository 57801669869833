import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { JOB_STATUSES, RPC_TYPES } from 'utils';
import {
  ThreeDRpcIcon,
  TwoDRpcIcon,
  WarningOutlined,
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  Reload,
    RefreshOutlined,
} from 'components/icons';
import { SpinAnimation, FovModal, FovButton, IndeterminateProgress } from 'components/generic';
import { FovSelect, FovOption } from 'components/form';
import { VerticalSpacer, HorizontalSpacer } from 'components/layout';
import { ButtonsContainer, IconButton } from './styles';
import { downloadMenuItems, customStartCase } from './utils';
import { FovConfirmation } from 'components/notifications';
import { Body } from 'components/typography';
import { hasAccess } from 'navigation/navigationHelpers';
import { getCreatorUser } from 'store/actions/userData';
import { withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const handleStatus = (status, jobType, scaling, isCardView) => {
  if (status === JOB_STATUSES.PROCESSING || status === JOB_STATUSES.QUEUED) {
    return (
      <SpinAnimation duration={3000} absolutePosition={isCardView}>
        <Reload
          height={scaling.jobStatus.processIcon.height}
          width={scaling.jobStatus.processIcon.width}
        />
      </SpinAnimation>
    );
  }

  if (status === JOB_STATUSES.FAILED) {
    return (
      <WarningOutlined
        width={scaling.jobStatus.width}
        height={scaling.jobStatus.height}
      />
    );
  }

  if (status === JOB_STATUSES.COMPLETED) {
    return (
      <ThreeDRpcIcon
        width={scaling.jobStatus.width}
        height={scaling.jobStatus.height}
      />
    );
  }

  if (status === JOB_STATUSES.COMPLETED && jobType === RPC_TYPES.png) {
    return (
      <TwoDRpcIcon
        width={scaling.jobStatus.width}
        height={scaling.jobStatus.height}
      />
    );
  }
};

const ModelActions = withTheme(props => {
  const {
    theme,
    scaling,
    rpc_guid,
    isCardView,
    handlers,
    title,
    job_type,
    formats,
    purchased,
    job_status,
  } = props;

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const onCreators = location.pathname.includes('creators');
  const temporaryRole = userDataStore.temporaryRole;
  const userRoles = temporaryRole !== 'default' ? [ temporaryRole ] : userDataStore?.data?.roles;
  const { setIsDeleting, onDelete, onDownload, onReprocess, setIsReprocessing } = handlers;

  const downloadArgs = {
    formats,
    rpc_guid,
    onDownload,
    onComplete: resetDownloadState,
  };

  const menuItems = downloadMenuItems(downloadArgs)?.filter(item => {
    if (hasAccess(userRoles, item.allowedRoles)) {
      return item;
    }
  }) || [];

  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const [ confirmReprocess, setConfirmReprocess ] = useState(false);
  const [ downloadModal, setDownloadModal ] = useState(false);
  const defaultFormat = menuItems.find(item => {
    return item.format?.toLowerCase() === 'rpc' && item.status?.toLowerCase() === 'available';
  })?.format ?? menuItems[0]?.format;
  const [ selectedFormat, setSelectedFormat ] = useState(defaultFormat);
  const [ downloadInProgress, setDownloadInProgress ] = useState(false);
  const [ cookies ] = useCookies([ 'userAuth' ]);

  const token = cookies.archvision_token;
  const emptyFormatList = useMemo(() => {
    if (menuItems.length && !selectedFormat) {
      setSelectedFormat(defaultFormat);
    }

    return !menuItems.length || !selectedFormat;
  }, [ downloadModal, formats, selectedFormat, menuItems ]);

  const selectedTheme = userDataStore.userPreferences.theme;
  const navigate = useNavigate();
  const light = constants.THEME.LIGHT;
  const enabledIconColor = selectedTheme === light ? 'darkGrey' : 'lightGrey';
  const iconColor = rpc_guid ? enabledIconColor : 'mediumGrey';
  const errorColor = theme.colors[selectedTheme].error;
  const errorHoveredColor = theme.hexToRgba(errorColor, 0.8);
  const isImage = job_type === RPC_TYPES.png;

  const handleDeleting = e => {
    setIsDeleting(true);
    onDelete(e);
    e.stopPropagation();
  };

    const handleReprocessing = e => {
        setIsReprocessing(true);
        onReprocess(e);
        e.stopPropagation();
    };

  function resetDownloadState() {
    setDownloadInProgress(false);
  }

  const createDownloadMenu = () => {
    const handleDownloadClose = () => {
      if (!emptyFormatList) {
        setSelectedFormat(defaultFormat);
      }

      setDownloadModal(false);
    };

    const handleDownload = () => {
      if (!emptyFormatList) {
        const formatObject = menuItems.find(item => item.format === selectedFormat);
        setDownloadInProgress(true);
        formatObject?.callback();
      }
    };

    const handleDownloadChange = (e, newVal) => {
      setSelectedFormat(newVal);
    };

    return (
      <FovModal
        visible={downloadModal}
        title='Select Download Format'
        onClose={handleDownloadClose}
        noOverflow={true}
      >
        <FovSelect
          name={selectedFormat}
          value={selectedFormat}
          placeholder={selectedFormat}
          onChange={handleDownloadChange}
          disabled={emptyFormatList}
          onOpen={() => dispatch(getCreatorUser(token))}
        >
          {!emptyFormatList && menuItems.map((item, index) => {
            return (
              <FovOption
                key={index}
                value={customStartCase(item.format)}
                after={_.capitalize(item.status.toLowerCase())}
                disabled={item.status?.toLowerCase() !== 'available'}
              >
                {customStartCase(item.format)}
              </FovOption>
            );
        })}

          {emptyFormatList && (
            <FovOption value={'No formats found'}>
              No formats found, check permissions.
            </FovOption>
          )}
        </FovSelect>

        <VerticalSpacer size={20} />


        <FovButton
          callback={handleDownload}
          disabled={downloadInProgress || emptyFormatList}
        >
          {downloadInProgress
            ? <IndeterminateProgress $color={theme.colors[selectedTheme].darkGrey} />
            : 'Download'
          }
        </FovButton>
      </FovModal>
    );
  };

  return (
    <>
      <ButtonsContainer
        isCardView={isCardView}
        height={scaling.buttonContainer.height}
      >
        {!isImage && rpc_guid && (
          <>
            <IconButton
              theme={theme}
              $selectedTheme={selectedTheme}
              onClick={e => {
                e.stopPropagation();
                navigate(`/${onCreators ? 'creator-' : ''}models/${rpc_guid}`);
              }}
              disabled={!rpc_guid}
              width={scaling.icons.width}
              height={scaling.icons.height}
              icon={
                <EditOutlined
                  color={theme.colors[selectedTheme][iconColor]}
                  hoverColor={rpc_guid && theme.colors[selectedTheme].accent}
                  width={scaling.icons.width}
                  height={scaling.icons.height}
                />
              }
            />

            {isCardView ? (
              <VerticalSpacer size={scaling.icons.spacerBetween} />
            ) : (
              <HorizontalSpacer size={scaling.icons.spacerBetween * 3} />
            )}
          </>
        )}

        {rpc_guid && (
          <>
            <IconButton
              theme={theme}
              $selectedTheme={selectedTheme}
              width={scaling.icons.width}
              height={scaling.icons.height}
              disabled={!rpc_guid}
              onClick={e => {
                e.stopPropagation();
                setDownloadModal(true);
              }}
              icon={
                <DownloadOutlined
                  color={theme.colors[selectedTheme][iconColor]}
                  hoverColor={rpc_guid && theme.colors[selectedTheme].accent}
                  width={scaling.icons.width}
                  height={scaling.icons.height}
                />
              }
            />

            {createDownloadMenu()}

            {isCardView ? (
              <VerticalSpacer size={scaling.icons.spacerBetween + 8} />
            ) : (
              <HorizontalSpacer size={scaling.icons.spacerBetween * 3} />
            )}
          </>
        )}

        {(!purchased && !onCreators && !rpc_guid && job_status === JOB_STATUSES.FAILED) && (
            <>
                <IconButton
                    theme={theme}
                    $selectedTheme={selectedTheme}
                    width={scaling.icons.width}
                    height={scaling.icons.height}
                    onClick={e => {
                        e.stopPropagation();
                        setConfirmReprocess(true);
                    }}
                    icon={
                        <RefreshOutlined
                            color={theme.colors[selectedTheme][iconColor]}
                            hoverColor={theme.colors[selectedTheme].accent}
                            width={scaling.icons.width}
                            height={scaling.icons.height}
                        />
                    }
                />
                {isCardView ? (
                  <VerticalSpacer size={scaling.icons.spacerBetween + 8} />
                ) : (
                  <HorizontalSpacer size={scaling.icons.spacerBetween * 3} />
                )}
            </>
        )}


        {(!purchased && !onCreators || hasAccess(userRoles, [ 'creator' ])) && (
          <IconButton
            theme={theme}
            $selectedTheme={selectedTheme}
            width={scaling.icons.width}
            height={scaling.icons.height}
            onClick={e => {
              e.stopPropagation();
              setConfirmDelete(true);
            }}
            icon={
              <DeleteOutlined
                color={theme.colors[selectedTheme][iconColor]}
                hoverColor={theme.colors[selectedTheme].accent}
                width={scaling.icons.width}
                height={scaling.icons.height}
              />
            }
          />
        )}
      </ButtonsContainer>

      <FovConfirmation
        onConfirm={handleDeleting}
        onClose={() => setConfirmDelete(false)}
        confirmationText='Delete'
        isOpened={confirmDelete}
        confirmColor={errorColor}
        confirmHoverColor={errorHoveredColor}
      >
        <Body size='Medium'>
          {`Are you sure you want to delete ${title || 'selected model'}?`}
        </Body>
      </FovConfirmation>

      <FovConfirmation
        onConfirm={handleReprocessing}
        onClose={() => setConfirmReprocess(false)}
        confirmationText='Reprocess'
        isOpened={confirmReprocess}
      >
        <Body size='Medium'>
            {`Are you sure you want to reprocess ${title || 'selected model'}?`}
        </Body>
      </FovConfirmation>

    </>
  );
});

export { ModelActions, handleStatus };
