import { Body, H5, VerticalSpacer } from 'components';
import { withTheme } from 'styled-components';
import { Tag, TagsContainer } from './styles';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

const ModelMetadata = withTheme(props => {

    const { selectedModel, theme } = props;
    const { category, subcategory, rpc_guid, tags } = selectedModel || {};

    const userDataStore = useSelector(state => state.userData);

    const selectedTheme = userDataStore.userPreferences.theme;

    const buildTags = () => {
        return tags?.map((tag, index) => {
            return (
                <Tag
                    key={index}
                    theme={theme}
                    $selectedTheme={selectedTheme}
                >
                    {tag}
                </Tag>
            );
        });
    };

    return (
        <Fragment>
            <Body>
                <H5>Category: </H5>{category}
                <VerticalSpacer size={8} />
                {subcategory &&
                    (<Fragment>
                        <H5>Subcategory:</H5>{subcategory}
                        <VerticalSpacer size={8} />
                    </Fragment>
                    )}
                <H5>GUID: </H5>{rpc_guid}
                <VerticalSpacer size={8} />
                <H5>Tags: </H5>
            </Body>
            <TagsContainer>
                {buildTags(tags)}
            </TagsContainer>
        </Fragment>
    );
});

export { ModelMetadata };