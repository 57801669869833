import { FovSlider, TextureSwatch } from 'components';
import constants from 'constants/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBlobFromBase64String,
  getChannelFromTexture,
  loadImage,
  updateCanvas,
} from 'utils';
import {
  ChannelTextContainer,
  EndChannelWrapper,
  RoughnessValueContainer,
} from '../MaterialStyles';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Roughness = () => {
  const dispatch = useDispatch();
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const roughnessValue = selectedMaterial?.roughness;
  const [ roughnessImage, setRoughnessImage ] = useState(null);

  const metallicReflectanceTexture = true;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };
  useEffect(() => {
    const b64 = selectedMaterial.metallicTexture.url.split(',')[1];
    const roughnessBlob = createBlobFromBase64String(b64, 'image/png');
    if (roughnessBlob) {
      loadImage(roughnessBlob).then(data => {
        const img = getChannelFromTexture(data, 'G');
        setRoughnessImage(img);
      });
    }
  }, [ selectedMaterial?.metallicTexture?.url, serializedData ]);

  useEffect(() => {
    !selectedMaterial?.metallicTexture?.url && setRoughnessImage(null);
  }, [ setRoughnessImage, selectedMaterial ]);

  useEffect(() => {
    return () => setRoughnessImage(null);
  }, []);

  const buildRoughnessSection = () => {
    return (
      <ChannelTextContainer>
        <RoughnessValueContainer>
          <FovSlider
            label="Roughness"
            defaultValue={roughnessValue ?? 1}
            onAfterChange={newVal => {
              const inboundData = {
                id: selectedMaterialId,
                roughness: newVal,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />

          <EndChannelWrapper>
            {metallicReflectanceTexture && (
              <TextureSwatch
                texture={
                  {
                    id: selectedMaterial?.metallicTexture?.name,
                    name: selectedMaterial?.metallicTexture?.name,
                    img: roughnessImage,
                  } || null
                }
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    roughnessTexture: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
                removeChannel={() => {
                  const inboundData = {
                    id: selectedMaterialId,
                    removeRoughnessTexture: true,
                  };

                  updateCanvas(removeArgs, inboundData);
                }}
              />
            )}
          </EndChannelWrapper>
        </RoughnessValueContainer>
      </ChannelTextContainer>
    );
  };

  return <div>{buildRoughnessSection()}</div>;
};

export default Roughness;
