import React, { useRef, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import {
  FovPopover,
  VerticalSpacer,
  UploadOutlined,
  DeleteOutlined,
  NoTextureOutlined,
  DownloadOutlined,
  FovDivider,
} from 'components';
import { NormalMenuItem } from 'components/blocks/appHeaderStyles';
import { getColor } from 'utils';
import _ from 'lodash';

const SwatchContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const PreviewImage = styled('img')`
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  border-radius: 0.3125rem;
  background-repeat: round;
  background-size: cover;
  background-color: ${props => getColor(props, 'white')};
  height: 2.25rem;
  width: 2.25rem;
  cursor: pointer;
  outline: none;
`;

const ChannelHolder = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
`;

const UploadInput = styled('input')`
  display: none;
`;

const DownloadLink = styled('a')`
  display: none;
`;

const NoSwatch = styled('div')`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.3125rem;
  background-color: ${props => getColor(props, 'white')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  cursor: pointer;
  outline: none;
`;

const TextureSwatch = withTheme(props => {
  const {
      theme,
      texture,
      updateChannel,
      removeChannel,
      fallbackTexture,
  } = props;
  const [ swatchVal, setSwatchVal ] = useState('');

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const textureUploadRef = useRef();
  const textureDownloadRef = useRef();

  const onImageLoad = imgData => {
    updateChannel(imgData);
  };

  const content = (imgSrc, channelId) => {
    return (
      <SwatchContainer>
        <NormalMenuItem
          theme={theme}
          $selectedTheme={selectedTheme}
          channelId={channelId}
          onClick={() => textureUploadRef.current?.click()}
        >
          <UploadOutlined width={20} height={20} color={theme.colors[selectedTheme].onPrimary} /> &nbsp;
          Upload
        </NormalMenuItem>

        {imgSrc && (
          <>
            <VerticalSpacer size={10} />
            <FovDivider />
            <VerticalSpacer size={10} />

            <NormalMenuItem
              theme={theme}
              $selectedTheme={selectedTheme}
              onClick={() => textureDownloadRef.current?.click()}
            >
              <DownloadOutlined width={20} height={20} color={theme.colors[selectedTheme].onPrimary} /> &nbsp;
              Download
              <VerticalSpacer size={10} />
              <FovDivider />
            </NormalMenuItem>

            <VerticalSpacer size={10} />

            <NormalMenuItem
              theme={theme}
              $selectedTheme={selectedTheme}
              onClick={() => {
                removeChannel();
                setSwatchVal('');
              }}
            >
              <DeleteOutlined width={20} height={20} color={theme.colors[selectedTheme].onPrimary} /> &nbsp;
              Remove
            </NormalMenuItem>
          </>
        )}
      </SwatchContainer>
    );
  };

  const handleUpload = e => {
    const file = e.currentTarget.files[0];

    if (!_.isEqual(file, swatchVal)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onImageLoad({ name: file.name, src: reader.result });

      setSwatchVal(reader.result);
    }
  };

    const getFallbackTexture = () => {
        if (fallbackTexture) {
            return fallbackTexture;
        }

        return (
          <NoSwatch theme={theme} $selectedTheme={selectedTheme}>
            <NoTextureOutlined />
          </NoSwatch>
        );
    };

  return (
    <ChannelHolder>
      <FovPopover
        noArrow={true}
        right={40}
        top={0}
        padding={8}
        content={texture && content(texture.img, texture.id, texture.name)}
      >
        {texture?.img
            ? (
                <PreviewImage
                    theme={theme}
                    $selectedTheme={selectedTheme}
                    src={texture.img}
                />

            )
            : getFallbackTexture()
        }
      </FovPopover>

      <UploadInput
        ref={textureUploadRef}
        type="file"
        accept="image/*"
        value={swatchVal || ''}
        onChange={handleUpload}
      />

      {texture && (
        <DownloadLink
          download={texture.name?.includes('.')
            ? texture.name
            : `${texture.name}.png`
          }
          href={texture.img}
          ref={textureDownloadRef}
        />
      )}
    </ChannelHolder>
  );
});

export { TextureSwatch };
