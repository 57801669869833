import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { formatRpcImage, getScaledValues, buildTitle } from './utils';
import { FovTypography } from 'components/typography';
import {
  CardContainer,
  IconContainer,
  ImageContainer,
  CustomImage,
  TitleContainer,
} from './styles';
import { IndeterminateProgress } from 'components/generic';
import { ModelActions, handleStatus } from './ModelActions';
import { DollarCircleOutlined } from 'components';

const ModelCard = withTheme(props => {
  const {
    job,
    onDownload,
    onDelete,
      onReprocess,
    cardScale,
    theme,
    onCardClick,
    cardSelected,
    isCardView,
  } = props;

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const [ isDeleting, setIsDeleting ] = useState(false);
    const [ isReprocessing, setIsReprocessing ] = useState(false);
  const [ meta, setMeta ] = useState(null);

  const { status, rpc_guid, job_type, title, formats, purchased, product_id: productId } = job;
  const imageUrl = formatRpcImage(rpc_guid, job_type);
  const scaling = getScaledValues(cardScale);

  useEffect(() => {
    if (isDeleting) {
      setIsDeleting(false);
    }
      if (isReprocessing) {
        setIsReprocessing(false);
      }

    return () => {
        setIsDeleting(false);
        setIsReprocessing(false);
    };
  }, [ userDataStore ]);

  useEffect(() => {
    if (!meta) {
      metaInfo();
    }
  }, [ meta ]);

  const metaInfo = () => {
    const image = new Image();

    image.onload = () => {
      const metaInfo = {
        src: imageUrl,
        width: image.width,
        height: image.height,
        aspect: {
          landscape: image.width > image.height,
          portrait: image.height > image.width,
          square: image.height === image.width,
        },
      };

      setMeta(metaInfo);
    };

    image.src = imageUrl;
  };

  const getDimension = dimension => {
    if (meta?.aspect.square) {
      return scaling.image[dimension === 'width' ? 'width' : 'height'];
    }

    if (dimension === 'width') {
      return meta?.aspect.portrait ? 'auto' : scaling.image.width;
    }

    if (dimension === 'height') {
      return meta?.aspect.portrait ? scaling.image.height : 'auto';
    }

    return scaling.image.default.height;
  };

  const handlers = {
    setIsDeleting,
    onDelete,
    onDownload,
      onReprocess,
      setIsReprocessing,
  };

  return (
    <CardContainer
      theme={theme}
      $selectedTheme={selectedTheme}
      column={scaling.grid.column}
      row={scaling.grid.row}
      width={scaling.cardContainer.width}
      height={scaling.cardContainer.height}
      onClick={() => onCardClick()}
      $cardSelected={rpc_guid && cardSelected}
      $previewDisabled={!rpc_guid}
    >
      <IconContainer>
        {!productId
          ? handleStatus(status, job_type, scaling, isCardView)
          : <DollarCircleOutlined width={scaling.jobStatus.width} height={scaling.jobStatus.height} />
        }
      </IconContainer>

      <ImageContainer>
        {!isDeleting && (
          <CustomImage
            $disabled={!rpc_guid}
            width={getDimension('width')}
            height={getDimension('height')}
            src={imageUrl}
          />
        )}

        {isDeleting && <IndeterminateProgress text='Deleting...' />}
      </ImageContainer>

      <ModelActions
        title={title}
        scaling={scaling}
        rpc_guid={rpc_guid}
        job_type={job_type}
        job_status={status}
        selectedTheme={selectedTheme}
        isCardView={isCardView}
        handlers={handlers}
        formats={formats}
        purchased={purchased}
      />

      <TitleContainer>
        <FovTypography fontSize={scaling.font.size}>
          {buildTitle(status, title, isCardView)}
        </FovTypography>
      </TitleContainer>
    </CardContainer>
  );
});

export { ModelCard };
