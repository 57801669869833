import { FovButton, FovDivider, VerticalSpacer } from 'components';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { downloadModel } from 'store/actions/rpc';
import styled, { withTheme } from 'styled-components';

import { customStartCase } from './utils';

const DownloadSection = styled('div')`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const FormatWrapper = styled('div')`
    display: grid;
    align-items: center;
    justify-content: end;
    gap: 0.5rem 1.5rem;
    grid-template-columns: 1fr auto;
    width: 100%;
`;

const DownloadWrapper = styled('div')`
    display: flex;
    justify-content: flex-end;
    width: auto;
`;

const ModelDownloadSection = withTheme(props => {

    const { selectedModel } = props;
    const { formats } = selectedModel;
    const [ cookies ] = useCookies([ 'user-auth' ]);
    const dispatch = useDispatch();

    const token = cookies.archvision_token;

    const handleDownload = useCallback((rpcGuid, format, onComplete) => {
        const args = { rpcGuid, format, token, download: true, onComplete };
        dispatch(downloadModel(args));
    }, []);

    return (
        <div>
            {
                Object.keys(formats).map(key => {
                    return (
                        <div key={key}>
                            <VerticalSpacer size={8} />
                            <DownloadSection>
                                    <FormatWrapper>
                                        {customStartCase(key)}
                                    </FormatWrapper>
                                    <DownloadWrapper>
                                        <FovButton
                                            callback={() => handleDownload(selectedModel.rpc_guid, key)}
                                            disabled={formats[key].toLowerCase() !== 'available'}
                                            >
                                            Download
                                        </FovButton>
                                    </DownloadWrapper>
                                    <VerticalSpacer size={8} />
                            </DownloadSection>
                            <FovDivider />
                            <VerticalSpacer size={4} />
                        </div>
                    );
                })
            }
        </div>
    );
});

export { ModelDownloadSection };
