import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  H3,
  VerticalSpacer,
  SearchInputField,
  HorizontalSpacer,
  FovModal,
  HubSpotForm,
  RefreshOutlined,
} from 'components';
import { decode } from 'utils';
import { HeaderArea, ActionArea, CreatorList, Title, CTA, Search, RefreshFiles } from './styles';
import { ViewOptions } from './ViewOptions';
import { paramsFromURL, paramsToObject } from 'utils/paramHelpers';
import { withTheme } from 'styled-components';
import { ModelContext } from './ModelContext';

const ModelsHeader = withTheme(props => {
  const { theme } = props;
  const { getModels, pageTitle } = useContext(ModelContext);
  const [ searchParams, setSearchParams ] = useSearchParams();

  const [ showModal, setShowModal ] = useState(false);

  const navigate = useNavigate();
  const searchFieldRef = useRef('');
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const params = paramsToObject(decode(paramsFromURL()));
  const modelsLoading = userDataStore.modelsLoading;

  useEffect(() => {
    if (!params.search) {
      searchFieldRef.current.value = '';
    }
  }, [ params.search ]);

  const handleBlur = () => {
    const value = searchFieldRef.current.value;

    if (value === '') {
      searchParams.delete('search');
      searchParams.set('page', 1);
      setSearchParams(searchParams);
      getModels();
      return;
    }

    setSearchParams({ ...params, search: value, page: 1 });
    getModels();
  };

  return (
    <HeaderArea>
      <VerticalSpacer size={8} />

      <Title>
        <H3>
          {pageTitle}
        </H3>

        {(
          <>
            <HorizontalSpacer size={8} />

            {modelsLoading &&
              <RefreshFiles>
                <RefreshOutlined spinIcon={modelsLoading} />
              </RefreshFiles>
            }
          </>
        )}

        <CreatorList theme={theme} $selectedTheme={selectedTheme}>
          {/*onCreators && '/ '}{typeof partners === 'string'
            ? [ partners ]?.sort().join(' / ')
            : partners?.sort().join(' / ')
          */}
        </CreatorList>
      </Title>

      <ActionArea>
        <CTA
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={() => setShowModal(!showModal)}
        >
          Request models
        </CTA>

        <HorizontalSpacer size={24} />

        <CTA
          theme={theme}
          $selectedTheme={selectedTheme}
          onClick={() => navigate('/welcome')}
        >
          Explore FOVEA
        </CTA>
      </ActionArea>

      <Search>
        <SearchInputField
          width={530}
          disabled={false}
          ref={searchFieldRef}
          defaultValue={searchFieldRef.current.value || decode(params.search)}
          onChange={e => searchFieldRef.current.value = e.target.value}
          onKeyUp={e => e.key === 'Enter' && e.target.blur()}
          onBlur={handleBlur}
        />
      </Search>

      <ViewOptions />

      <FovModal title='Request models' visible={showModal} maxWidth={560} onClose={() => setShowModal(false)}>
        <HubSpotForm
          region='na1'
          portalId='7125801'
          formId='166318d8-be75-444d-b44f-d3092c9c1cad'
          targetId='modelRequestForm'
        />
      </FovModal>
    </HeaderArea>
  );
});

export { ModelsHeader };