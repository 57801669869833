import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { TextureSwatch, ColorSwatch, HorizontalSpacer, FovSlider } from 'components';
import {
  ChannelTextContainer,
  BumpTextureContainer,
  EndChannelWrapper,
} from '../MaterialStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';

const  { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Ambient = withTheme(props => {
  const { theme } = props;
  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const ambientTextureStrength = selectedMaterial?.ambientTextureStrength;
  const selectedTheme = userDataStore.userPreferences.theme;
  const white = theme.colors[selectedTheme].white;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const buildAmbientSection = () => {
    return (
      <ChannelTextContainer>
        <BumpTextureContainer>
          <FovSlider
            label='Ambient'
            disabled={_.isEmpty(selectedMaterial?.ambientTexture?.url)}
            defaultValue={ambientTextureStrength ?? 1}
            min={0}
            max={1}
            onAfterChange={newVal => {
              const inboundData = {
                id: selectedMaterialId,
                ambientTextureStrength: newVal,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />

          <EndChannelWrapper>
              <ColorSwatch
                color={selectedMaterial?.ambientColor || white}
                updateChannel={newVal => {
                  const inboundData = {
                    id: selectedMaterialId,
                    ambientColor: newVal,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />

              <HorizontalSpacer size={15} />
            <TextureSwatch
              texture={{
                id: selectedMaterial?.ambientTexture?.name,
                name: selectedMaterial?.ambientTexture?.name,
                img: selectedMaterial?.ambientTexture?.url,
              } || null}
              updateChannel={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  ambientTexture: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
              removeChannel={() => {
                const inboundData = {
                  id: selectedMaterialId,
                  removeAmbientTexture: true,
                };

                updateCanvas(removeArgs, inboundData);
              }}
            />
          </EndChannelWrapper>
        </BumpTextureContainer>
      </ChannelTextContainer>
    );
  };

  return (
    <div>
      {buildAmbientSection()}
    </div>
  );
});

export default Ambient;

