import React, { Fragment, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import { updateModelMetadata } from 'store/actions/userData';
import { useCookies } from 'react-cookie';
import {
  VerticalSpacer,
  FovSelect,
  FovOption,
  FovInput,
  FovFieldError,
  FovButton,
  Tags,
  IndeterminateProgress,
} from 'components';
import { TagsContainer } from 'pages/ModelCanvas/SideMenus/Publish/publishStyles';
import { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';


const EditMetadataForm = withTheme(props => {
  const { theme, selectedModel } = props;
  const { category, title: fileName, tags: modelTags, rpc_guid, subcategory } = selectedModel || {};
  const [ cookies ] = useCookies([ 'userAuth' ]);

  const canvasStore = useSelector(state => state.canvas);
  const userDataStore = useSelector(state => state.userData);
  const rpcStore = useSelector(state => state.rpc);
  const token = cookies.archvision_token;
  const selectedTheme = userDataStore.userPreferences.theme;
  const darkGrey = theme.colors[selectedTheme].darkGrey;
  const modelsLoading = userDataStore.modelsLoading;
  const serializedData = canvasStore.serializedData;
  const statistics = serializedData?.metadata.statistics;
  const rpcCategories = rpcStore.rpcCategories;
  const userId = userDataStore.data.userId;
  const formCategory = subcategory || category;
  const tags = useMemo(() => !_.isEmpty(modelTags) ? modelTags?.join(',') : '', [ selectedModel ]);
  const formikRef = useRef();
  const dispatch = useDispatch();

  const defaults = {
    contentName: fileName || '',
    categoryName: formCategory || 'Other',
    tags,
  };

  const schema = yup.object().shape({
    contentName: yup.string().required('Name is required.'),
    categoryName: yup.string().required('Category is required.'),
    tags: yup.string().required('At least one tag is required.'),
  });

  useEffect(() => {
    const form = formikRef.current;

    form?.setFieldValue('contentName', fileName);
    form?.setFieldValue('categoryName', formCategory);
    form?.setFieldValue('tags', tags);

    return () => form?.resetForm();
  }, [ selectedModel ]);

  const buildCategoriesOptions = rpcCategories => {
    if (!_.isEmpty(rpcCategories)) {
      return rpcCategories.map(category => {
        const hasChildren = category.children?.length > 0;
        const childrenArray = hasChildren && [ ...category.children, category ];
        const arrayWithNested = hasChildren ? childrenArray.reverse() : [ category ];

        return arrayWithNested.map((item, index) => {
          const { title, value, children } = item;

          return (
            <FovOption
              key={index}
              value={value}
              indent={!children}
              groupParent={children}
            >
              {title}
            </FovOption>
          );
        });
      });
    }

    return (
      <FovOption value='No categories found.'>
        No categories found.
      </FovOption>
    );
  };

  const categories = useMemo(() => {
    return buildCategoriesOptions(rpcCategories);
  }, [ rpcCategories ]);

  const handleSubmit = args => {
    const { values, setSubmitting } = args;

    const onComplete = () => setSubmitting(false);
    const payload = {
      title: values.contentName,
      tags: values.tags.split(','),
      category: values.categoryName,
    };

    dispatch(updateModelMetadata({ token, guid: rpc_guid, payload, onComplete, userId }));
  };

  const setRef = form => {
    if (!formikRef.current) {
      formikRef.current = form;
    }
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={schema}
      enableReinitialization={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Fragment>
          {setRef(form)}

          Name*
          <VerticalSpacer size={10} />

          <Field type='text' name='contentName'>
            {data => (
              <FovInput
                value={data.field.value}
                disabled={modelsLoading}
                placeholder={modelsLoading ? 'Model data loading...' : fileName || 'Enter a new name'}
                {...data.field}
                onChange={e => {
                  const newVal = e.target.value;
                  form.setFieldValue(data.field.name, newVal);
                }}
              />
            )}
          </Field>

          <FovFieldError name='contentName' />

          Category*
          <VerticalSpacer size={10} />

          <Field type='select' name='categoryName'>
            {data => (
              <FovSelect
                value={data.field.value}
                placeholder={data.field.value}
                disabled={_.isEmpty(rpcCategories)}
                onChange={(e, newVal) => form.setFieldValue(data.field.name, newVal)}
              >
                {categories}
              </FovSelect>
            )}
          </Field>

          <FovFieldError name='categoryName' />

          Tags*
          <VerticalSpacer size={10} />

          <TagsContainer theme={theme} $selectedTheme={selectedTheme}>
            <Field type='input' name='tags'>
              {data => (
                <Tags
                  values={_.isEmpty(data.field.value) ? [] : data.field.value.split(',')}
                  onChange={(e, tags) => form.setFieldValue(data.field.name, tags)}
                />
              )}
            </Field>
          </TagsContainer>

          <FovFieldError name='tags' />

          <FovButton
            type='submit'
            disabled={!statistics?.triangles || modelsLoading || form.isSubmitting}
            onClick={form.handleSubmit}
          >
            {form.isSubmitting
              ? <IndeterminateProgress $color={darkGrey} />
              : 'Save metadata'
            }
          </FovButton>
        </Fragment>
      )}
    </Formik>
  );
});

export { EditMetadataForm };