import React from 'react';
import * as Icon from 'components/icons';

export const getCategoryIcon = (name, color, hoverColor) => {
  const commonIconProps = {
    width: 18,
    height: 18,
    color,
    hoverColor,
  };

  switch(name?.toLowerCase()) {
    case 'testing':
      return <Icon.RefreshOutlined  {...commonIconProps}/>;
    case 'materials':
      return <Icon.MaterialOutlined {...commonIconProps} />;
    case 'geometry':
      return <Icon.GeometryOutlined {...commonIconProps} />;
    case 'lights':
      return <Icon.SunOutlined {...commonIconProps} />;
    case 'units':
      return <Icon.UnitsOutlined {...commonIconProps} />;
    case 'axis':
      return <Icon.AxisOutlined {...commonIconProps} />;
    case 'scale':
      return <Icon.CubeOutlined {...commonIconProps} />;

    case 'people':
      return <Icon.PeopleOutlined {...commonIconProps} />;

    case 'vegetation':
      return <Icon.LeafOutlined {...commonIconProps} />;
    case 'tree':
      return <Icon.TreeOutlined {...commonIconProps} />;
    case 'plant':
      return <Icon.PlantOutlined {...commonIconProps} />;
    case 'flower':
      return <Icon.FlowerOutlined {...commonIconProps} />;
    case 'shrub':
      return <Icon.ShrubOutlined {...commonIconProps} />;
    case 'grass':
      return <Icon.GrassOutlined {...commonIconProps} />;

    case 'transportation':
      return <Icon.TransportationOutlined {...commonIconProps} />;
    case 'car':
      return <Icon.CarOutlined {...commonIconProps} />;
    case 'van':
      return <Icon.VanOutlined {...commonIconProps} />;
    case 'truck':
      return <Icon.TruckOutlined {...commonIconProps} />;
    case 'plane':
      return <Icon.PlaneOutlined {...commonIconProps} />;
    case 'motorcycle':
      return <Icon.MotorcycleOutlined {...commonIconProps} />;
    case 'bus':
      return <Icon.BusOutlined {...commonIconProps} />;
    case 'bicycle':
      return <Icon.BicycleOutlined {...commonIconProps} />;
    case 'boat':
      return <Icon.BoatOutlined {...commonIconProps } />;

    case 'furniture':
      return <Icon.FurnitureOutlined {...commonIconProps} />;
    case 'table':
      return <Icon.TableOutlined {...commonIconProps} />;
    case 'chair':
      return <Icon.ChairOutlined {...commonIconProps} />;
    case 'stool':
      return <Icon.StoolOutlined {...commonIconProps} />;
    case 'sofa':
      return <Icon.SofaOutlined {...commonIconProps} />;
    case 'couch':
      return <Icon.CouchOutlined {...commonIconProps} />;
    case 'bench':
      return <Icon.BenchOutlined {...commonIconProps} />;
    case 'bookcase':
      return <Icon.BookcaseOutlined {...commonIconProps} />;
    case 'bed':
      return <Icon.BedOutlined {...commonIconProps} />;
    case 'dresser':
      return <Icon.DresserOutlined {...commonIconProps} />;
    case 'desk':
      return <Icon.DeskOutlined {...commonIconProps} />;
    case 'cabinet':
      return <Icon.CabinetOutlined {...commonIconProps} />;

    case 'other':
      return <Icon.LampOutlined {...commonIconProps} />;

    default:
      return null;
  }
};