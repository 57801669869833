import React, { Fragment, useState, useMemo, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Category } from './Category';
import { paramsToObject } from 'utils/paramHelpers';
import { getCategoryIcon } from './categoryUtils';
import {
  H4,
  VerticalSpacer,
  AppstoreOutlined,
  HorizontalSpacer,
  DocNote,
  FovSkeletonLoader,
} from 'components';
import { FilterSection, FilterTitle, TitleGroup, NoSelectionMessage, ClearAll } from './panelStyles';
import { withTheme } from 'styled-components';
import { decode } from 'utils';
import _ from 'lodash';
import { ModelContext } from 'pages/Home/ModelContext';

const Categories = withTheme(props => {
  const { theme } = props;
  const { getModels, workingData, searchCategoryParam, searchSubcategoryParam } = useContext(ModelContext);

  const { search } = useLocation();
  const params = paramsToObject(decode(search));
  const categorySearchParam = searchCategoryParam;
  const subcategorySearchParam = searchSubcategoryParam;

  const [ openedCategory, setOpenedCategory ] = useState(params[categorySearchParam]);
  const [ searchParams, setSearchParams ] = useSearchParams();

  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const userPreferences = userDataStore.userPreferences;
  const filterPanel = userPreferences?.filterPanel;
  const allPartners = useMemo(() => {
    return workingData?.allPartners?.map(partner => partner);
  }, [ workingData ]);
  const totalActiveModels = useMemo(() => {
    return workingData?.filteredTotalModels;
  }, [ workingData ]);

  useEffect(() => {
      getModels();
  }, []);

  const buildSubCategories = subCategories => {
    const workingSubCategories = subCategories?.map((category, index) => {
      const { categoryName, totalCategoryModels, filterCount } = category;
      const isActive = params[searchSubcategoryParam] && decodeURI(params[searchSubcategoryParam]) === categoryName;
      const differentCount = !_.isEqual(totalCategoryModels, filterCount);
      const isMisc = categoryName.toLowerCase() === 'misc';
      const subIcon = getCategoryIcon(isMisc ? openedCategory : categoryName, 'onPrimary', 'accent');

      return(
        totalCategoryModels === 0 ? null :
        <Fragment key={index}>
          <Category
            icon={subIcon}
            name={categoryName}
            modelCount={(isActive && differentCount) || (isActive && params.tags)
              ? filterCount
              : totalCategoryModels
            }
            total={totalCategoryModels}
            isSubCategory={true}
            isOpen={openedCategory === categoryName}
            isActive={isActive}
            callback={() => {
              delete params.tags;

              if (categoryName) {
                if (isActive) {
                  delete params[searchSubcategoryParam];
                  setSearchParams({ ...params, page: 1 });
                } else {
                  setSearchParams({ ...params, [searchSubcategoryParam]: categoryName, page: 1 });
                }
                getModels();
              }
            }}
          />
        </Fragment>
      );
    }).filter(item => item);

    return workingSubCategories;
  };

  const buildCategories = () => {
    const workingCategories = allPartners?.map((item, index) => {
      const { name, totalModels, categories: subCategories, filterCount } = item;
      const isActiveCategory = () => {
        if (!params[categorySearchParam]) false;

        if (subCategories.length > 0) {
          return params[searchCategoryParam] === name ||
                subCategories.some(sub => sub.categoryName.replaceAll('+', ' ') === params[subcategorySearchParam]);
        }
        return params[categorySearchParam] === name;
      };
      const isActive = isActiveCategory();
      const filterModelCount = !isActive && !params.tags ? totalModels : filterCount;
      return (
        totalModels === 0
          ? null
          : <Fragment key={index}>
              <Category
                icon={getCategoryIcon(name, 'onPrimary', 'accent')}
                name={name.replaceAll('_', ' ')}
                modelCount={filterModelCount}
                total={totalModels}
                subCategories={!_.isEmpty(subCategories) && subCategories.find(sub => sub.totalCategoryModels > 0)}
                isOpen={openedCategory === name}
                isActive={isActive}
                callback={() => {
                  setOpenedCategory(!_.isEmpty(subCategories) ? name : null);

                  if (name) {
                    delete params[subcategorySearchParam];
                    if (isActive) {
                      delete params[categorySearchParam];
                      setSearchParams({ ...params, page: 1 });
                      setOpenedCategory(null);
                    } else {
                      setSearchParams({ ...params, [categorySearchParam]: name, page: 1 });
                    }
                    getModels();
                  }
                }}
              />

              {openedCategory === name && buildSubCategories(subCategories)}
            </Fragment>
      );
    }).filter(item => item);

    return !_.isEmpty(workingCategories) ? workingCategories : (
      <NoSelectionMessage theme={theme} $selectedTheme={selectedTheme}>
        <p>No categories found.</p>
      </NoSelectionMessage>
    );
  };

  return (
    <FilterSection collapsed={filterPanel}>
      <FilterTitle>
        <TitleGroup>
          <AppstoreOutlined isTitleIcon={true} $selectedTheme={selectedTheme} />
          <HorizontalSpacer size={8} />
          <H4>Categories</H4>

          <DocNote path={[ 'filterMyFiles', 'categories' ]} top={-1} left={150} />
        </TitleGroup>

        {(params[searchCategoryParam] || params[searchSubcategoryParam]) && (
          <ClearAll
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={() => {
              searchParams.delete(searchCategoryParam);
              searchParams.delete(searchSubcategoryParam);
              searchParams.delete('tags');
              searchParams.set('page', 1);

              setSearchParams(searchParams);
              setOpenedCategory(null);
              getModels();
            }}
          >
            Clear all
          </ClearAll>
        )}
      </FilterTitle>

      <VerticalSpacer size={18} />
      <Category
        icon={null}
        name='All'
        modelCount={totalActiveModels}
        total={workingData?.totalModels}
        subCategories={false}
        callback={() => {
          searchParams.delete(searchCategoryParam);
          searchParams.delete(searchSubcategoryParam);
          searchParams.delete('tags');
          searchParams.set('page', 1);

          setSearchParams(searchParams);
          setOpenedCategory(null);
          getModels();
        }}
      />

      <FovSkeletonLoader type='categories' resource={workingData}>
        {buildCategories()}
      </FovSkeletonLoader>

      <VerticalSpacer size={42} />
    </FilterSection>
  );
});

export { Categories };