import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextureSwatch, FovSlider } from 'components';
import {
  ChannelTextContainer,
  BumpTextureContainer,
  EndChannelWrapper,
} from '../MaterialStyles';
import { updateCanvas } from 'utils';
import constants from 'constants/index';
import _ from 'lodash';

const  { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;

const Surface = () => {
  const dispatch = useDispatch();
  const canvasStore = useSelector(state => state.canvas);
  const serializedData = canvasStore.serializedData;
  const selectedMaterials = serializedData?.metadata.selectedMaterials || [];
  const selectedMaterial = selectedMaterials?.[0] || {};
  const selectedMaterialId = selectedMaterial?.materialId || '';
  const bumpValue = selectedMaterial?.bumpIntensity;

  const updateArgs = {
    dispatch,
    type: CANVAS_UPDATE_TYPES.MATERIAL,
    method: CANVAS_UPDATE_METHODS.UPDATE,
  };

  const removeArgs = { ...updateArgs, method: CANVAS_UPDATE_METHODS.REMOVE };

  const buildSurfaceSection = () => {
    return (
      <ChannelTextContainer>
        <BumpTextureContainer>
          <FovSlider
            label='Normal/Bump Map'
            disabled={_.isEmpty(selectedMaterial?.bumpTexture?.url)}
            defaultValue={bumpValue ?? 1}
            max={2}
            onAfterChange={newVal => {
              const inboundData = {
                id: selectedMaterialId,
                bumpIntensity: newVal,
              };

              updateCanvas(updateArgs, inboundData);
            }}
          />

          <EndChannelWrapper>
            <TextureSwatch
              texture={{
                id: selectedMaterial?.bumpTexture?.name,
                name: selectedMaterial?.bumpTexture?.name,
                img: selectedMaterial?.bumpTexture?.url,
              } || null}
              updateChannel={newVal => {
                const inboundData = {
                  id: selectedMaterialId,
                  bumpTexture: newVal,
                };

                updateCanvas(updateArgs, inboundData);
              }}
              removeChannel={() => {
                const inboundData = {
                  id: selectedMaterialId,
                  removeBumpTexture: true,
                };

                updateCanvas(removeArgs, inboundData);
              }}
            />
          </EndChannelWrapper>
        </BumpTextureContainer>
      </ChannelTextContainer>
    );
  };

  return (
    <div>
      {buildSurfaceSection()}
    </div>
  );
};

export default Surface;
