import { useSelector } from 'react-redux';
import styled, { withTheme, css } from 'styled-components';

const BoatSVG = styled('svg')`
color: ${props =>
  props.theme.colors[props.$selectedTheme][props.color || 'onPrimary']}
${props =>
  props.hoverColor &&
  css`
    &:hover {
      color: ${props.theme.colors[props.$selectedTheme][props.hoverColor]};
    }
  `};
`;

const BoatOutlined = withTheme(props => {
  const { width, height, color, hoverColor, theme } = props;
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;

  return (
    <BoatSVG 
        theme={theme}
        $selectedTheme={selectedTheme}
        hoverColor={hoverColor}
        color={color}
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='img'
        width={width || 24}
        height={height || 24}
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 500 500'
    >
        <path 
            fill='currentColor'
            d="M480.741,362.365c-3.629-6.593-11.928-11.379-19.73-11.379c-0.335,0-0.669,0.009-0.996,0.026l-172.008,8.957v-21.36 l138.655-7.221c11.072-0.576,20.578-6.55,25.429-15.979c5.24-10.183,4.326-22.59-2.442-33.183L287.825,28.6 C286.122,12.548,272.504,0,256.007,0c-17.647,0-32.004,14.355-32.004,32v52.31l-6.115,8.016 c-5.042,6.543-15.717,19.61-29.235,36.152c-37.696,46.131-100.785,123.339-127.045,164.23 c-9.227,14.369-10.582,30.837-3.535,42.979c4.999,8.61,13.765,13.623,23.672,13.623c0.511,0,1.025-0.013,1.541-0.041 l140.716-7.327v21.36l-173.658,9.044c-6.765,0.353-13.393,3.758-17.299,8.889c-3.342,4.386-4.549,9.675-3.396,14.896 l21.332,96.67c2.577,11.663,10.917,19.199,21.249,19.199H385.9c11.006,0,15.784-8.595,18.351-13.211 c0.518-0.936,1.01-1.82,1.496-2.585l74.671-118.001C483.445,373.417,483.565,367.497,480.741,362.365z M288.007,68.551 l143.66,225.157c2.636,4.125,3.177,8.589,1.454,11.942c-1.398,2.715-4.015,4.248-7.57,4.433l-137.545,7.163V68.551z M245.338,32 c0-5.881,4.787-10.665,10.669-10.665c5.882,0,10.665,4.784,10.665,10.665v329.082l-21.335,1.112V32z M82.176,327.965 c-2.592,0.133-4.392-0.816-5.65-2.988c-2.431-4.188-2.435-12.225,3.034-20.74c25.601-39.866,88.207-116.482,125.614-162.26 c7.215-8.828,13.614-16.66,18.83-23.103v44.284c-5.876,7.641-22.395,29.096-36.171,46.78c-3.622,4.648-2.787,11.35,1.858,14.97 c1.948,1.518,4.256,2.253,6.549,2.253c3.178,0,6.319-1.412,8.423-4.112c6.3-8.089,13.172-16.96,19.341-24.946V320.58 L82.176,327.965z M387.719,484.794c-0.809,1.278-1.502,2.528-2.116,3.63c-0.373,0.673-0.868,1.563-1.288,2.241H72.761 c-0.267-0.402-0.667-1.201-0.946-2.464l-20.847-94.467c0.158-0.043,0.325-0.074,0.487-0.082l407.36-21.214L387.719,484.794z"></path> <path d="M184.713,231.406c-4.565-3.721-11.284-3.037-15.007,1.53c-0.367,0.448-0.695,0.844-0.977,1.182 c-3.775,4.524-3.167,11.251,1.356,15.025c1.996,1.665,4.419,2.477,6.829,2.477c3.052,0,6.086-1.306,8.197-3.834 c0.325-0.391,0.708-0.85,1.132-1.371C189.964,241.847,189.28,235.128,184.713,231.406z"
        />
        <path 
            fill='currentColor'
            d="M387.852,419.993c0.164,0,0.331-0.003,0.498-0.012l0.927-0.043c5.886-0.263,10.442-5.249,10.178-11.134 c-0.265-5.885-5.257-10.442-11.134-10.179l-0.95,0.044c-5.884,0.27-10.436,5.26-10.165,11.145 C377.468,415.534,382.185,419.993,387.852,419.993z"
        /> 
        <path 
            fill='currentColor'
            d="M290.64,425.534c0.289,0,0.581-0.012,0.875-0.035c14.755-1.196,36.19-2.498,65.523-3.981 c5.884-0.298,10.411-5.308,10.115-11.192c-0.298-5.885-5.338-10.412-11.192-10.115c-29.962,1.514-50.988,2.793-66.17,4.024 c-5.873,0.476-10.246,5.622-9.771,11.494C280.473,421.307,285.14,425.534,290.64,425.534z"
        />
        <path 
            fill={theme.colors[selectedTheme].transparent}
            stroke='currentColor'
            strokeWidth='0.15'
            d="M480.741,362.365c-3.629-6.593-11.928-11.379-19.73-11.379c-0.335,0-0.669,0.009-0.996,0.026l-172.008,8.957v-21.36 l138.655-7.221c11.072-0.576,20.578-6.55,25.429-15.979c5.24-10.183,4.326-22.59-2.442-33.183L287.825,28.6 C286.122,12.548,272.504,0,256.007,0c-17.647,0-32.004,14.355-32.004,32v52.31l-6.115,8.016 c-5.042,6.543-15.717,19.61-29.235,36.152c-37.696,46.131-100.785,123.339-127.045,164.23 c-9.227,14.369-10.582,30.837-3.535,42.979c4.999,8.61,13.765,13.623,23.672,13.623c0.511,0,1.025-0.013,1.541-0.041 l140.716-7.327v21.36l-173.658,9.044c-6.765,0.353-13.393,3.758-17.299,8.889c-3.342,4.386-4.549,9.675-3.396,14.896 l21.332,96.67c2.577,11.663,10.917,19.199,21.249,19.199H385.9c11.006,0,15.784-8.595,18.351-13.211 c0.518-0.936,1.01-1.82,1.496-2.585l74.671-118.001C483.445,373.417,483.565,367.497,480.741,362.365z M288.007,68.551 l143.66,225.157c2.636,4.125,3.177,8.589,1.454,11.942c-1.398,2.715-4.015,4.248-7.57,4.433l-137.545,7.163V68.551z M245.338,32 c0-5.881,4.787-10.665,10.669-10.665c5.882,0,10.665,4.784,10.665,10.665v329.082l-21.335,1.112V32z M82.176,327.965 c-2.592,0.133-4.392-0.816-5.65-2.988c-2.431-4.188-2.435-12.225,3.034-20.74c25.601-39.866,88.207-116.482,125.614-162.26 c7.215-8.828,13.614-16.66,18.83-23.103v44.284c-5.876,7.641-22.395,29.096-36.171,46.78c-3.622,4.648-2.787,11.35,1.858,14.97 c1.948,1.518,4.256,2.253,6.549,2.253c3.178,0,6.319-1.412,8.423-4.112c6.3-8.089,13.172-16.96,19.341-24.946V320.58 L82.176,327.965z M387.719,484.794c-0.809,1.278-1.502,2.528-2.116,3.63c-0.373,0.673-0.868,1.563-1.288,2.241H72.761 c-0.267-0.402-0.667-1.201-0.946-2.464l-20.847-94.467c0.158-0.043,0.325-0.074,0.487-0.082l407.36-21.214L387.719,484.794z"></path> <path d="M184.713,231.406c-4.565-3.721-11.284-3.037-15.007,1.53c-0.367,0.448-0.695,0.844-0.977,1.182 c-3.775,4.524-3.167,11.251,1.356,15.025c1.996,1.665,4.419,2.477,6.829,2.477c3.052,0,6.086-1.306,8.197-3.834 c0.325-0.391,0.708-0.85,1.132-1.371C189.964,241.847,189.28,235.128,184.713,231.406z"
        />
        <path 
            fill={theme.colors[selectedTheme].transparent}
            stroke='currentColor'
            strokeWidth='0.15'
            d="M387.852,419.993c0.164,0,0.331-0.003,0.498-0.012l0.927-0.043c5.886-0.263,10.442-5.249,10.178-11.134 c-0.265-5.885-5.257-10.442-11.134-10.179l-0.95,0.044c-5.884,0.27-10.436,5.26-10.165,11.145 C377.468,415.534,382.185,419.993,387.852,419.993z"
        /> 
        <path 
            fill={theme.colors[selectedTheme].transparent}
            stroke='currentColor'
            strokeWidth='0.15'
            d="M290.64,425.534c0.289,0,0.581-0.012,0.875-0.035c14.755-1.196,36.19-2.498,65.523-3.981 c5.884-0.298,10.411-5.308,10.115-11.192c-0.298-5.885-5.338-10.412-11.192-10.115c-29.962,1.514-50.988,2.793-66.17,4.024 c-5.873,0.476-10.246,5.622-9.771,11.494C280.473,421.307,285.14,425.534,290.64,425.534z"
        /> 
    </BoatSVG>
  );
});

export { BoatOutlined };
