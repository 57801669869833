import React, { useMemo, Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { FovPopover, FovTooltip } from 'components/generic';
import { DocNote } from 'components';
import { MESSAGE_TYPES } from 'components/notifications';
import {
  VerticalSpacer,
  HorizontalSpacer,
  ThemeSwitcher,
  FovDivider,
} from 'components/layout';
import { DownOutlined, LogoutOutlined, UserOutlined, CreditsOutlined } from 'components/icons';
import { Logo } from '..';
import { createRpc, getRpcCategories } from 'store/actions/rpc';
import { getCreatorUser, getUserData, invalidateAuth, setUserPreferences } from 'store/actions/userData';
import { addMessage } from 'store/actions/app';
import { AppHeaderFileName } from '../blocks';
import {
  AppHeaderContainer,
  LogoContainer,
  MenuLink,
  BasicLink,
  Menu,
  CreateCaretDownContainer,
  MenuItem,
  ProfileMenuContainer,
  Profile,
  Credits,
  InfoAndSupport,
  NormalMenuItem,
  Ellipses,
  MenuContainer,
  HelpIcon,
} from './appHeaderStyles';
import { getAvailChannels, availLogout } from 'store/actions/avail';
import { wssCallback } from 'utils/jobSockets';
import { ProtectedContent, allRoles, subscribedRoles, superUsers } from 'components/generic/ProtectedContent';
import {
  setSerializedData,
  clearUpdateData,
  setReinitializeCanvas,
  setExportModel,
  setExportedModelData,
  setGuidURL,
} from 'store/actions/canvas';
import RPCForm from 'pages/ModelCanvas/SideMenus/RPCForm';
import AvailLoginContainer from 'pages/AvailLoginContainer';
import constants from 'constants/index';
import docsItems from 'pages/Documentation/docItems';
import _ from 'lodash';


const { ARCHVISION_TOKEN } = constants.TOKEN_NAMES;

const AppHeader = withTheme(props => {
  const { theme } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userDataStore = useSelector(state => state.userData);
  const rpcStore = useSelector(state => state.rpc);
  const [ availModalOpen, setAvailModalOpen ] = useState(false);
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ rpcCreateType, setRpcCreateType ] = useState();
  const [ createPopover, setCreatePopover ] = useState(false);
  const [ contentPopover, setContentPopover ] = useState(false);
  const [ , , removeCookie ] = useCookies([ 'userAuth' ]);
  const modelsPage = pathname.includes('/models');
  const onCreators = pathname.includes('/creators');
  const onMyFiles = pathname.includes('/my-files');
  const onJobs = pathname.includes('/jobs');
  const guid = pathname.split('models/')[1];
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const [ availLoggedIn, setAvailLoggedIn ] = useState();
  const token = cookies.archvision_token;
  const creditBalance = userDataStore?.data?.user_points || 0;
  const selectedTheme = userDataStore.userPreferences.theme;
  const userRoles = userDataStore?.data?.roles;
  const simulatedRole = userDataStore.temporaryRole;
  const simulatedSuper = superUsers.includes(simulatedRole) || simulatedRole === 'default';
  const isSuper = userRoles?.find(role => superUsers.includes(role)) && simulatedSuper;
  const showDocumentation = userDataStore.userPreferences.showDocumentation;
  const firstRun = JSON.parse(localStorage.getItem('fovFirstRun')) ?? true;
  const userModels = userDataStore.userModels;
  const userId = userDataStore?.data?.userId;
  const userEmail = userDataStore?.data?.email;

  const fileName = useMemo(() => {
    return !_.isEmpty(rpcStore.publishName)
      ? rpcStore.publishName
      : userDataStore.creatorUser?.find(item => item.rpc_guid === guid)?.title;
  }, [ guid, rpcStore.publishName, userDataStore.creatorUser ]);

  const clearFirstRunExperience = () => {
    if (firstRun) {
      const message = 'Toggle references from the help menu, or by pressing F1.';
      const config = { type: MESSAGE_TYPES.info, timer: 6 };

      localStorage.removeItem('fovReadItems');
      localStorage.setItem('fovFirstRun', false);

      dispatch(addMessage([ { message, config } ]));
    }

    dispatch(setUserPreferences({ showDocumentation: !showDocumentation }));
  };

  const toggleDocumentation = e => {
    const key = e.key;

    if (key === 'F1') {
      e.preventDefault();
      clearFirstRunExperience();
    }
  };

  useEffect(() => {
    dispatch(getRpcCategories(token));
  }, []);

  useEffect(() => {
    if ((modelsPage) && !rpcStore.creatorUser) {
      dispatch(getCreatorUser(token));
    }
  }, [ modelsPage, rpcStore.creatorUser ]);

  useEffect(() => {
    removeEventListener('keydown', toggleDocumentation);
    window.addEventListener('keydown', toggleDocumentation);

    return () => removeEventListener('keydown', toggleDocumentation);
  }, [ showDocumentation ]);

  const submitImageBasedCreate = useCallback((values, debugState, onComplete) => {
    const wssArgs = {
      token,
      dispatch,
      userModels,
      handleCreditUpdate: () => dispatch(getUserData(token)),
    };

    const createArgs = {
      token,
      userId,
      userEmail,
      payload: values,
      debugState,
      onComplete,
      handleSockets: () => wssCallback(wssArgs),
    };

    const message = 'Packaging model…';
    const config = {
      type: MESSAGE_TYPES.loading,
      showModal: true,
    };

    values.email = userDataStore?.data?.email;
    values.author = userDataStore?.data?.name;

    dispatch(addMessage([ { message, config } ]));
    setModalOpen(false);
    dispatch(createRpc(createArgs));
  }, [ userDataStore, dispatch, createRpc, addMessage, rpcCreateType ]);

  const openRpcModal = type => {
    setRpcCreateType(type);
    setModalOpen(true);
    dispatch(getAvailChannels(cookies.archvision_token, setAvailLoggedIn));
    setCreatePopover(false);
  };

  const logout = () => {
    dispatch(invalidateAuth(token, () => removeCookie(ARCHVISION_TOKEN)));
  };

  const loginAvail = () => {
    setAvailModalOpen(true);
  };

  const logoutAvail = () => {
    dispatch(availLogout(cookies.archvision_token, setAvailLoggedIn));
  };

  const closeOktaLoginModal = () => {
    setAvailModalOpen(false);
  };

  const loginAvailButton = () => {
    return (
      <Fragment key={420}>
        <ProtectedContent>
          <NormalMenuItem theme={theme} $selectedTheme={selectedTheme} onClick={loginAvail}>
            Log in to AVAIL
          </NormalMenuItem>

          <VerticalSpacer size={10} />
          <FovDivider />
          <VerticalSpacer size={10} />
        </ProtectedContent>
      </Fragment>
    );
  };

  const logoutAvailButton = () => {
    return (
      <Fragment key={419}>
        <ProtectedContent>
          <NormalMenuItem theme={theme} $selectedTheme={selectedTheme} onClick={logoutAvail}>
            Log out of AVAIL
          </NormalMenuItem>

          <VerticalSpacer size={10} />
          <FovDivider />
          <VerticalSpacer size={10} />
        </ProtectedContent>
      </Fragment>
    );
  };

  const logoutButton = () => {
    const logoutButtonText = userId ? 'Log out' : 'Log in' ;
    return (
      <NormalMenuItem key={421} theme={theme} $selectedTheme={selectedTheme} onClick={logout}>
        <LogoutOutlined height={16} width={16} /> {logoutButtonText}
      </NormalMenuItem>
    );
  };

  const infoSupportMenu = () => {
    const menuItems = [
      { path: '//archvision.com/terms-and-conditions/', text: 'Terms and Conditions' },
      { path: '//archvision.com/privacy-policy/', text: 'Privacy Policy' },
    ];

    return menuItems.map((item, index) => {
      const { path, callback, text, useDivider } = item;

      return (
        <MenuLink
          key={index}
          theme={theme}
          $selectedTheme={selectedTheme}
          to={path || ''}
          rel='noreferrer'
          target={path?.includes('//') ? '_blank' : '_self'}
        >
          <NormalMenuItem
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={e => {
              if (callback) {
                e.preventDefault();
                callback();
              }
            }}
          >
            {text}
            {index !== menuItems.length - 1 && <VerticalSpacer size={10} />}
            {useDivider && (
              <>
                <FovDivider />
                <VerticalSpacer size={10} />
              </>
            )}
          </NormalMenuItem>
        </MenuLink>
      );
    });
  };

  const helpMenu = () => {
    const menuItems = [
      {
        path: Object.values(docsItems)[0][0].path,
        text: 'Documentation',
      },
      { path: '/roadmap',
        text: 'View road map' ,
      },
      { path: '//discourse.archvision.com/c/fovea/24',
        text: 'Give feedback',
      },
      {
        path: '//helpcenter.archvision.com/kb-tickets/new',
        text: 'Contact support',
      },
      {
        callback: () => clearFirstRunExperience(),
        text: `${showDocumentation ? 'Hide' : 'Show'} references (F1)`,
      },
    ];

    return menuItems.map((item, index) => {
      const { path, text, callback } = item;

      return (
        <div key={index}>
          {(index !== 0) && (
            <VerticalSpacer size={10} />
          )}

          <MenuLink
            key={index}
            theme={theme}
            $selectedTheme={selectedTheme}
            to={path || ''}
            rel='noreferrer'
            target={path?.includes('//') ? '_blank' : '_self'}
          >
            <MenuItem
              theme={theme}
              $selectedTheme={selectedTheme}
              onClick={e => {
                if (callback) {
                  e.preventDefault();
                  callback();
                }
              }}
            >
              {text}
            </MenuItem>
          </MenuLink>
        </div>
      );
    });
  };

  const contentMenu = () => {
    const menuItems = [
      {
        path: `/my-files${onMyFiles ? location.search : ''}`,
        text: 'My Files',
        allowedRoles: allRoles,
      },
      {
        path: `/jobs${onJobs ? location.search : ''}`,
        text: 'My Jobs',
        allowedRoles: allRoles,
      },
      {
        path: `/creators${onCreators ? location.search : ''}`,
        text: 'Creators',
        allowedRoles: subscribedRoles,
      },
      {
        path:`/purchased-models`,
        text: 'Purchased',
        allowedRoles: allRoles,
      },
    ];

    return menuItems.map((item, index) => {
      const { path, text, allowedRoles } = item;

      return (
        <ProtectedContent key={index} requiredRoles={allowedRoles}>
          {(index !== 0) && (
            <VerticalSpacer size={10} />
          )}

          <MenuLink
            theme={theme}
            $selectedTheme={selectedTheme}
            to={path || ''}
          >
            <MenuItem
              theme={theme}
              $selectedTheme={selectedTheme}
              active={createPopover}
            >
              {text}
            </MenuItem>
          </MenuLink>
        </ProtectedContent>
      );
    });
  };

  const createMenu = () => {
    const menuItems = [
      {
        path: null,
        text: 'From File',
        callback: () => openRpcModal(),
        allowedRoles: allRoles,
      },
      {
        path: '/create',
        text: 'From gLTF',
        callback: () => {
          setCreatePopover(false);
          dispatch(setSerializedData(null));
          dispatch(clearUpdateData());
          dispatch(setReinitializeCanvas(true));
          dispatch(setExportModel(false));
          dispatch(setExportedModelData(null));
          dispatch(setGuidURL(null));
        },
        allowedRoles: allRoles,
      },
    ];

    return menuItems.map((item, index) => {
      const { path, text, allowedRoles, callback } = item;

      return (
        <ProtectedContent key={index} requiredRoles={allowedRoles}>
          {(index !== 0) && (
            <VerticalSpacer size={10} />
          )}

          {(!path && (
            <BasicLink
              theme={theme}
              $selectedTheme={selectedTheme}
              onClick={callback}
            >
              <MenuItem
                theme={theme}
                $selectedTheme={selectedTheme}
                active={createPopover}
              >
                {text}
              </MenuItem>
            </BasicLink>
          ))}

          {path && (
            <MenuLink
              theme={theme}
              $selectedTheme={selectedTheme}
              to={path}
              onClick={callback}
            >
              <MenuItem
                theme={theme}
                $selectedTheme={selectedTheme}
                active={createPopover}
              >
                {text}
              </MenuItem>
            </MenuLink>
          )}
        </ProtectedContent>
      );
    });
  };

  return (
    <AppHeaderContainer $selectedTheme={selectedTheme}>
      <LogoContainer>
        <Link to='/'>
          <Logo />
          <DocNote path={[ 'header', 'logo' ]} top={23} left={110} />
        </Link>
      </LogoContainer>

      {!modelsPage &&  userId && (
        <MenuContainer>
          <FovPopover
            position='bottom'
            top={45}
            left={36}
            content={contentMenu()}
            callback={() => setContentPopover(!contentPopover)}
          >
            <Menu
              theme={theme}
              $selectedTheme={selectedTheme}
              active={contentPopover}
            >
              <DocNote path={[ 'header', 'contentMenu' ]} left={-30} />
              Content <HorizontalSpacer size={7} />
              <CreateCaretDownContainer active={contentPopover}>
                <DownOutlined width={16} height={16} />
              </CreateCaretDownContainer>
            </Menu>
          </FovPopover>

          <HorizontalSpacer size={50} />

          <FovPopover
            position='bottom'
            top={45}
            left={isSuper ? 7 : 21}
            content={createMenu()}
            callback={() => setCreatePopover(!createPopover)}
          >
            <Menu
              theme={theme}
              $selectedTheme={selectedTheme}
              active={createPopover}
            >
              <DocNote path={[ 'header', 'createMenu' ]} left={-30} />
              Create <HorizontalSpacer size={7} />
              <CreateCaretDownContainer active={createPopover}>
                <DownOutlined width={16} height={16} />
              </CreateCaretDownContainer>
            </Menu>
          </FovPopover>

          <HorizontalSpacer size={50} />

        </MenuContainer>
      )}

      {modelsPage && (
        <AppHeaderFileName
          path={(location.pathname?.includes('/models') && '/my-files')
            || (location.pathname?.includes('/creator-models') && '/creators')
          }
          pageName='Models'
          fileName={fileName}
        />
      )}

      <ProfileMenuContainer theme={theme} $selectedTheme={selectedTheme}>
        <div>
          <ThemeSwitcher />
          <HorizontalSpacer size={35} />
        </div>

        <FovPopover
          position='bottom'
          top={45}
          left={-101}
          $minWidth={232}
          content={helpMenu()}
        >
          <DocNote path={[ 'header', 'helpMenu' ]} bottom={-25} />
          <InfoAndSupport
            theme={theme}
            $selectedTheme={selectedTheme}
          >
              <HelpIcon>?</HelpIcon>
          </InfoAndSupport>

          <HorizontalSpacer size={10} />
        </FovPopover>

        <FovPopover
          position='bottom'
          top={45}
          left={-101}
          $minWidth={232}
          content={infoSupportMenu()}
        >
          <DocNote path={[ 'header', 'infoSupportMenu' ]} bottom={-25} />
          <InfoAndSupport
            theme={theme}
            $selectedTheme={selectedTheme}
          >
            <Ellipses>•••</Ellipses>
          </InfoAndSupport>

          <HorizontalSpacer size={10} />
        </FovPopover>

        <Profile>
          <FovPopover
            position='bottom'
            top={45}
            content={[
              typeof CefSharp === 'undefined' ? (availLoggedIn ? logoutAvailButton() : loginAvailButton()) : null,
              logoutButton(),
            ]}
          >
            <DocNote path={[ 'header', 'profileMenu' ]} bottom={-31} />

            <UserOutlined /> <HorizontalSpacer size={15} />
            {(userDataStore.data?.firstName) || ''}
            <HorizontalSpacer size={7} />
            {(userDataStore.data?.lastName) || ''}
          </FovPopover>
          {userId && (
            <Credits onClick={_.noop}>
              <FovTooltip
                position='left'
                content='Credit balance.'
                top={-4}
                right={64}
              >

                <CreditsOutlined width={18} height={18} />
                <HorizontalSpacer size={10} />

                <strong>
                  {creditBalance?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                </strong>
              </FovTooltip>
            </Credits>
          )}
        </Profile>
      </ProfileMenuContainer>

      <RPCForm
        isVisible={modalOpen}
        setIsVisible={setModalOpen}
        onSubmit={(values, debugState, onComplete) => submitImageBasedCreate(values, debugState, onComplete)}
        isModelForSaving={true}
        setAvailModalOpen={setAvailModalOpen}
        setAvailLoggedIn={setAvailLoggedIn}
      />

      <AvailLoginContainer
        isVisible={availModalOpen}
        closeMe={closeOktaLoginModal}
        onCancel={() => setAvailModalOpen(false)}
        setAvailLoggedIn={tf => {
          setAvailLoggedIn(tf);
        }}
      />
    </AppHeaderContainer>
  );
});

export { AppHeader };
