import React, { useEffect, useMemo, useState, useCallback, useContext, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { downloadModel, deleteModel, reprocessModel } from 'store/actions/rpc';
import { updateModelPreviewImage } from 'store/actions/userData';
import {
  VerticalSpacer,
  H3,
  H4,
  H5,
  FovSkeletonLoader,
  FovLoader,
  DocNote,
  FovDrawer,
  Body,
  FovButton,
  IndeterminateProgress,
  FovPagination,
  FovDivider,
} from 'components';
import { decode, updateCanvas, wssCallback } from 'utils';
import { ModelCard } from './ModelCard';
import { EditMetadataForm } from './EditMetadataForm';
import { TableFullWidth, ModelsArea, PreviewContainer, PreviewTitle, UpdatePreviewButton } from './styles';
import { listColumns } from './listColumns';
import { NoSelectionMessage } from 'components/filterPanel/panelStyles';
import { paramsFromURL, paramsToObject } from 'utils/paramHelpers';
import { withTheme } from 'styled-components';
import { clearMessages, redirectToWordpressProduct } from 'store/actions/app';
import { PreviewCanvas } from 'pages';
import { ratios } from 'pages/ModelCanvas/SideMenus/Camera';
import { hasAccess } from 'navigation/navigationHelpers';
import { superUsers } from 'components/generic/ProtectedContent';
import constants from 'constants/index';
import Statistics from 'pages/ModelCanvas/SideMenus/Properties/Statistics';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { ModelContext } from '../ModelContext';
import { ModelDownloadSection } from './ModelDownloadSection';
import { ModelMetadata } from './ModelMetadata';

const { CANVAS_UPDATE_TYPES, CANVAS_UPDATE_METHODS } = constants;
const VIEWS = { CARD: 0, TABLE: 1 };

const Models = withTheme(props => {
  const { theme } = props;
  const [ selectedModel, setSelectedModel ] = useState(null);
  const [ previewURL, setPreviewURL ] = useState(null);
  const [ updatingPreview, setUpdatingPreview ] = useState(false);
  const [ isRedirecting, setIsRedirecting ] = useState(false);
  const [ cookies ] = useCookies([ 'userAuth' ]);
  const { getModels, workingData } = useContext(ModelContext);

  const dispatch = useDispatch();
  const token = cookies.archvision_token;
  const userDataStore = useSelector(state => state.userData);
  const canvasStore = useSelector(state => state.canvas);
  const selectedTheme = userDataStore.userPreferences.theme;
  const darkGrey = theme.colors[selectedTheme].darkGrey;
  const modelsLoading = userDataStore.modelsLoading;
  const userPreferences = userDataStore.userPreferences;
  const activeView = userPreferences?.modelView;
  const cardScale = userPreferences?.cardScaling;
  const onCreators = location.pathname.includes('/creators');
  const temporaryRole = userDataStore.temporaryRole;
  const userRoles = temporaryRole !== 'default' ? [ temporaryRole ] : userDataStore?.data?.roles;
  const isSuperUser = hasAccess(userRoles, superUsers);
  const creators = useMemo(() => userDataStore.creators, [ userDataStore.creators ]);
  const userModels = useMemo(() => userDataStore.userModels, [ userDataStore.userModels ]);
  const userJobs = useMemo(() => userDataStore.userJobs, [ userDataStore.userJobs ]);
  const purchasedModels = useMemo(() => userDataStore.purchasedModels, [ userDataStore.purchasedModels ]);
  const serializedData = useMemo(() => canvasStore.serializedData, [ canvasStore ]);
  const screenshotData = serializedData?.metadata?.screenshotData;
  const userId = userDataStore.data.userId;
  const [ searchParams, setSearchParams ] = useSearchParams();
  const params = paramsToObject(decode(paramsFromURL()));

  const [ models, totalCount, currentPage, pageSize ] = useMemo(() => {
    const models = workingData?.filteredResults?.flatMap(partner => partner.models) || [];

    return [ models, workingData?.filteredTotalModels, workingData?.currentPage, workingData?.pageSize ];
  }, [ onCreators, creators, userModels, userJobs, purchasedModels ]);

  const updateModelPreview = useCallback(() => {
    if (!_.isEmpty(screenshotData)) {
      const metadataArgs = {
        token,
        guid: selectedModel?.rpc_guid,
        payload: { previewImage: screenshotData },
        onComplete: () => setUpdatingPreview(false),
        userId,
      };

      dispatch(updateModelPreviewImage(metadataArgs));
      setUpdatingPreview(true);
    }
  }, [ screenshotData ]);

  const handleWordpressRedirect = useCallback(productId => {
    const args = {
      productId,
      openNew: true,
      onComplete: () => setIsRedirecting(false),
     };
    setIsRedirecting(true);
    dispatch(redirectToWordpressProduct(args));
  }, []);

  useEffect(() => {
    updateModelPreview();
  }, [ screenshotData ]);

  const convertToFile = async guid => {
    if (guid) {
      const onComplete = () => dispatch(clearMessages());
      const args = { rpcGuid: guid, format: 'DRACO_GLB', token, showModal: false, onComplete };

      previewCleanUp(true);
      dispatch(downloadModel(args))
        .then(url => setPreviewURL(url))
        .catch(e => console.error(e));
    }
  };

  const onModelClick = model => {
    const { rpc_guid } = model;
    const selectedGuid = selectedModel?.rpc_guid;


    if (rpc_guid) {
      if (!selectedModel || (rpc_guid !== selectedGuid)) {
        setSelectedModel(model);
        convertToFile(rpc_guid);
        return;
      }

      previewCleanUp();
    }
  };

  const handleOnDownload = useCallback((rpcGuid, format, onComplete) => {
    const args = { rpcGuid, format, token, download: true, onComplete };
    dispatch(downloadModel(args));
  }, []);

  const handleOnDelete = useCallback(model => {
    const ignoreLoading = true;
    dispatch(deleteModel(model, token)).then(() => getModels({ ignoreLoading }));
  }, []);

    const handleOnReprocess = useCallback(model => {
        const ignoreLoading = true;
        const wssArgs = { token, dispatch, userModels };

        const reprocessArgs = {
            job: model,
            token,
            handleSockets: () => wssCallback(wssArgs),
        };

        dispatch(reprocessModel(reprocessArgs)).then(() => getModels({ ignoreLoading }));
    }, []);

  const previewCleanUp = resetModel => {
    setPreviewURL(null);
    if (!resetModel) setSelectedModel(null);
    dispatch(clearMessages());
    setUpdatingPreview(false);
  };

  const handlePageChange = page => {
    if (Number(searchParams.get('page')) === page) return;

    searchParams.set('page', page);
    setSearchParams(searchParams);
    getModels();
  };

  const buildModels = () => {
    const isCardView = activeView === VIEWS.CARD;
    const shouldRender = !_.isEmpty(models);

    if (shouldRender) {
      const modelsAsList = (
        <TableFullWidth
          dataSource={models}
          columns={listColumns(isCardView, handleOnDownload, handleOnDelete, selectedTheme)}
          rowKey={`row-${Math.random()}`}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      );

      const modelsAsCards = models?.map((model, index) => {
        const { rpc_guid, job_guid } = model;
        const guid = job_guid || rpc_guid;
        const selectedGuid = selectedModel?.job_guid || selectedModel?.rpc_guid;

        return (
          <ModelCard
            key={index}
            job={model}
            onDownload={handleOnDownload}
            onDelete={() => handleOnDelete(model)}
            onReprocess={() => handleOnReprocess(model)}
            cardScale={cardScale}
            onCardClick={() => onModelClick(model)}
            isCardView={isCardView}
            cardSelected={selectedModel && guid === selectedGuid}
          />
        );
      });

      const modelsList = (<>
        {modelsAsCards},
        <FovPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </>);

      return isCardView ? modelsList : modelsAsList;
    }

    return (
      <NoSelectionMessage theme={theme} $selectedTheme={selectedTheme}>
        <VerticalSpacer size={25} />

        <H3>
          {_.isEmpty(params.creators) && onCreators && 'No creators selected'}
          {_.isEmpty(models) && (
            `${_.isEmpty(params.creators) && onCreators ? ' and n' : 'N'}o matching content found.`
          )}
        </H3>

        {_.isEmpty(models) && params.search && (
          <p>
            No results returned for &quot;{decode(params.search)}&quot;.
          </p>
        )}

        <p>
          {_.isEmpty(params.creators) && onCreators && 'Please select a creator to view their content'}
          {_.isEmpty(models) && (
            `${_.isEmpty(params.creators) && onCreators
                ? ' and t'
                : 'T'}ry refining your ${params.search ? 'search' : 'filters'}.`
          )}
        </p>
      </NoSelectionMessage>
    );
  };

  return (
    <>
      <ModelsArea>
        <DocNote path={[ 'content', 'models' ]} top={5} left={2} />

        <FovSkeletonLoader type='files' resource={models}>
          <FovLoader text='Filtering results' resource={!modelsLoading}>
            {buildModels()}
          </FovLoader>
        </FovSkeletonLoader>
      </ModelsArea>

      <FovDrawer
        closeDrawer={() => previewCleanUp()}
        isOpen={previewURL}
        title={selectedModel?.title}
      >
        <VerticalSpacer size={48} />

        <PreviewContainer theme={theme} $selectedTheme={selectedTheme}>
          <PreviewCanvas previewURL={previewURL} />

          {(isSuperUser || !onCreators) && (
            <UpdatePreviewButton $theme={theme} $selectedTheme={selectedTheme}>
              <FovButton
                $theme={theme}
                $selectedTheme={selectedTheme}
                borderRadius='0 0 0.25rem 0.25rem'
                text={updatingPreview
                  ? <IndeterminateProgress $color={darkGrey} />
                  : _.isEmpty(serializedData) ? 'Loading model...' : 'Update preview'
                }
                disabled={updatingPreview || _.isEmpty(serializedData)}
                callback={() => {
                  const updateArgs = {
                    dispatch,
                    type: CANVAS_UPDATE_TYPES.CAMERA,
                    method: CANVAS_UPDATE_METHODS.UPDATE,
                  };

                  const inboundData = {
                    takeScreenshot: true,
                    aspectRatio: ratios.broadcast.value,
                    imageDataOnly: true,
                  };

                  updateCanvas(updateArgs, inboundData);
                }}
              />
            </UpdatePreviewButton>
          )}

          <VerticalSpacer size={16} />

          <Body>
            <PreviewTitle>
              <H4>{selectedModel?.title}</H4>
              {onCreators && (
                <Fragment>
                  <VerticalSpacer size={16} />
                  <H5>Creator: {selectedModel?.creator}</H5>
                  {selectedModel?.product_id && (
                    <Fragment>
                      <VerticalSpacer size={8} />
                      <FovButton
                        callback={() => handleWordpressRedirect(selectedModel.product_id)}
                        disabled={isRedirecting}
                      >
                        { isRedirecting ? 'Redirecting...' : 'Purchase Now' }
                      </FovButton>
                    </Fragment>
                  )}
                </Fragment>
              )}

              {serializedData && <Statistics />}
              <VerticalSpacer size={serializedData ? 32 : 16} />

              {
              (isSuperUser || !onCreators) && selectedModel
                ? <EditMetadataForm selectedModel={selectedModel} />
                : <ModelMetadata selectedModel={selectedModel} />
              }
            </PreviewTitle>
            {selectedModel && (
              <Fragment>
                <VerticalSpacer size={8} />
                <FovDivider />
                <VerticalSpacer size={16} />
                <H4>Download by Format</H4>
                <ModelDownloadSection selectedModel={selectedModel} />
              </Fragment>
            )}
            <VerticalSpacer size={16} />

          </Body>
        </PreviewContainer>
      </FovDrawer>
    </>
  );
});

export { Models };
