import React, { useState, useEffect } from 'react';
import makeRoutes, { buildAuthUrl } from 'navigation/navigationHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Routes } from 'react-router-dom';
import { AppContainer, AppHeader, Footer, FovBanner } from 'components';
import { FovLoader } from 'components/layout';
import {
  getUserData,
  setIsAuthorizing,
  resetIsAuthorizing,
  getNewAccessToken,
  invalidateAuth,
} from 'store/actions/userData';
import { paramsFromHash, paramsToObject } from 'utils/paramHelpers';
import { hasPendingJob, wssCallback } from 'utils/jobSockets';
import constants from 'constants/index';

const { ARCHVISION_TOKEN, ARCHVISION_REFRESH_TOKEN } = constants.TOKEN_NAMES;

const AppNavigator = () => {
  const dispatch = useDispatch();
  const publicPages = [
    '/',
    '/welcome',
    '/documentation',
    '/documentation/overview',
  ];

  const shouldReset = publicPages.includes(location.pathname);
  const userDataStore = useSelector(state => state.userData);
  const cookieAgreement = JSON.parse(localStorage.getItem('fovAcceptedCookies'));

  const [ cookies, setCookie, removeCookie ] = useCookies([ 'userAuth' ]);
  const [ acceptedCookies, setAcceptedCookies ] = useState(cookieAgreement);

  const token = cookies.archvision_token;
  const userRoles = userDataStore?.data?.roles;
  const userData = userDataStore.data;
  const userJobs = userDataStore.userJobs;

  useEffect(() => {
    dispatch(setIsAuthorizing());
    handleAuthorization();
  }, []);

  useEffect(() => {
    if (hasPendingJob(userJobs?.incompleteJobs) && token) {
      const wssArgs = {
        token,
        dispatch,
        userJobs,
        handleCreditUpdate: () => dispatch(getUserData(token)),
      };

      wssCallback(wssArgs);
    }
  }, [ token, userJobs, wssCallback, hasPendingJob ]);

  useEffect(() => {
    if (userData && userDataStore.isAuthorizing) {
      dispatch(resetIsAuthorizing());
    }
  }, [ userData, userDataStore.isAuthorizing ]);

  const clearTokens = () => {
    removeCookie(ARCHVISION_TOKEN);
    localStorage.removeItem(ARCHVISION_TOKEN);
    localStorage.removeItem(ARCHVISION_REFRESH_TOKEN);
  };

  const saveTokens = (newToken, refreshToken) => {
    setCookie(ARCHVISION_TOKEN, newToken);
    localStorage.setItem(ARCHVISION_TOKEN, newToken);
    localStorage.setItem(ARCHVISION_REFRESH_TOKEN, refreshToken);
  };

  function handleAuthorization() {
    const params = paramsToObject(paramsFromHash());
    const accessToken = params.access_token;
    const refreshToken = params.refresh_token;
    const existingToken = token || localStorage.getItem(ARCHVISION_TOKEN);
    const existingRefreshToken = localStorage.getItem(ARCHVISION_REFRESH_TOKEN);
    if (location.pathname === '/documentation') {
      window.location.href += '/overview';
    }
    if (existingToken) {
      // Checks to see if existing token is valid. Auth invalidation and bad cookie
      // removal will occur automatically, if token is invalid.
      // User will be redirected to authentication form as part of auth invalidation.
      if (!token) {
        setCookie(ARCHVISION_TOKEN, existingToken);
      }

      dispatch(
        getUserData(existingToken, clearTokens, saveTokens),
      );
      return;
    }

    if (existingRefreshToken) {
      dispatch(
        getNewAccessToken(existingRefreshToken, clearTokens, saveTokens),
      );
      return;
    }

    if (accessToken) {
      dispatch(getUserData(accessToken, clearTokens, saveTokens));
      saveTokens(accessToken, refreshToken);
      return;
    }

    if (shouldReset) {
      dispatch(resetIsAuthorizing());
      return;
    }

    window.location.href = buildAuthUrl();
  }

  return (
    <AppContainer>
      <FovLoader
        resource={!userDataStore.isAuthorizing}
        text='Checking authentication...'
      >
        <AppHeader />

        <Routes>
          {makeRoutes(userRoles)}
        </Routes>

        {!acceptedCookies && (
          <FovBanner
            text='FOVEA uses cookies to manage your session.'
            maxWidth={700}
            useAccept={true}
            useReject={true}
            rejectText='Log out'
            callback={accept => {
              if (accept) {
                localStorage.setItem('fovAcceptedCookies', JSON.parse(true));
                return setAcceptedCookies(true);
              }

              dispatch(invalidateAuth(token, () => removeCookie(ARCHVISION_TOKEN)));
            }}
          />
        )}

        <Footer />
      </FovLoader>
    </AppContainer>
  );
};
export default AppNavigator;