import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Formik, Field } from 'formik';
import { getAvailChannels } from 'store/actions/avail';
import { Tags } from 'components/tags';
import {
  ToggleContainer,
  TagsContainer,
  HeightContainer,
  Label,
  LabeledSliderContainer,
  OptimizationControlContainer,
  OptimizationTogglesContainer,
} from './RPCFormStyles';
import {
  FovModal,
  FovFieldError,
  FovDivider,
  FovToggle,
  FovButton,
  FovSelect,
  FovOption,
  FovInput,
  VerticalSpacer,
  IndeterminateProgress,
  FovUploader,
  FovSlider,
} from 'components';
import { withTheme } from 'styled-components';
import { ProtectedContent } from 'components/generic/ProtectedContent';
import _ from 'lodash';
import * as yup from 'yup';
import { SUPPORTED_EXTENSIONS } from 'utils';

const units = [
  { label: 'in', factor: 1 },
  { label: 'ft', factor: 12 },
  { label: 'm', factor: 39.37 },
  { label: 'cm', factor: 0.393701 },
];

const buildCategoriesOptions = rpcCategories => {
  if (!_.isEmpty(rpcCategories)) {
    return rpcCategories.map(category => {
      const hasChildren = category.children?.length > 0;
      const childrenArray = hasChildren && [ ...category.children, category ];
      const arrayWithNested = hasChildren ? childrenArray.reverse() : [ category ];

      return arrayWithNested.map((item, index) => {
        const { title, value, children } = item;

        return (
          <FovOption
            key={index}
            value={value}
            indent={!children}
            groupParent={children}
          >
            {title}
          </FovOption>
        );
      });
    });
  }

  return (
    <FovOption value='No categories found.'>
      No categories found.
    </FovOption>
  );
};

const RPCForm = props => {
  const {
    isVisible,
    setIsVisible,
    onSubmit,
    isModelForSaving,
    setAvailLoggedIn,
    theme,
  } = props;

  const [ isImageProvided, setIsImageProvided ] = useState(false);
  const [ compactRPCEnabled, setCompactRPCEnabled ] = useState(false);
  const [ useModelOptimization, setUseModelOptimization ] = useState(false);
  const [ optimizationAmount, setOptimizationAmount ] = useState(0.99);
  const [ estimatedFacesCount, setEstimatedFacesCount ] = useState(0);
  const [ debugState, setDebugState ] = useState(false);
  const [ cookies ] = useCookies([ 'userAuth' ]);

  const dispatch = useDispatch();
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const rpcStore = useSelector(state => state.rpc);
  const darkGrey = theme.colors[selectedTheme].darkGrey;
  const rpcCategories = rpcStore.rpcCategories;
  const token = cookies.archvision_token;
  const creditBalance = userDataStore?.data?.user_points || 0;

  const defaults = {
    contentName: '',
    categoryName: 'Other',
    description: '',
    height: '',
    heightFactor: `${units[0].factor}`,
    files: '',
  };

  const schema = yup.object().shape({
    contentName: yup.string().required('Name is required.'),
    categoryName: yup.string().required('Category is required.'),
    description: yup.string().required('At least one tag is required.'),
    files: yup.mixed().required('A file is required.'),
    height: yup.number().when('isImage', {
      is: () => isImageProvided === true,
      then: yup.number()
        .typeError('Must be numeric value.')
        .required('Height is required.')
        .positive('Must be positive number.'),
      otherwise: yup.number().optional(),
    }),
  });

  const categories = useMemo(() => {
    return buildCategoriesOptions(rpcCategories);
  }, [ rpcCategories ]);

  const unitOptions = () => {
    return units.map((unit, index) => (
      <FovOption key={index} value={unit.factor}>
        {unit.label}
      </FovOption>
    ));
  };

  const cleanup = () => {
    setIsImageProvided(false);
    setCompactRPCEnabled(false);
    setUseModelOptimization(false);
    setOptimizationAmount(0.99);
    setEstimatedFacesCount(0);
    setDebugState(false);
  };

  const handleClose = () => {
    if (isVisible) {
      setIsVisible(false);
      cleanup();
    }
  };

  const handleCompactRPCChange = value => {
    setCompactRPCEnabled(value);

    if (value) {
      setUseModelOptimization(false);
    }
  };

  const handleMeshOptimizationChange = value => {
    setUseModelOptimization(value);

    if (value) {
      setCompactRPCEnabled(false);
    }
  };

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const {
      height,
      heightFactor,
      files,
      optimizationAmount,
      ...baseValues
    } = values;


    const onComplete = () => {
      setSubmitting(false);
      handleClose();
    };

    let payload = { ...baseValues };

    const withHeight = {
      height: +(height * heightFactor),
      heightFactor,
    };


    const withFiles = { files };
    const withOptimization = { optimizationAmount };
    const withoutFiles = {
      optimizedMesh: compactRPCEnabled,
      useModelOptimization,
    };

    if (isImageProvided) {
      payload = { ...payload, ...withHeight };
    }

    if (isModelForSaving) {
      payload = { ...payload, ...withFiles };
      return onSubmit(payload, debugState, onComplete);
    }

    if (useModelOptimization) {
      payload = { ...payload, ...withoutFiles, ...withOptimization };
      return onSubmit(payload, debugState, onComplete);
    }

    payload = { ...payload, ...withoutFiles };
    onSubmit(payload, debugState, onComplete);
  };



  useEffect(() => {
    dispatch(getAvailChannels(token, setAvailLoggedIn));
  }, []);

  const renderNameField = () => {
    return (
      <Fragment>
        Name*
        <VerticalSpacer size={10} />

        <Field type='text' name='contentName'>
          {data => {
            return (
              <FovInput
                name={data.field.name}
                value={data.field.value}
                {...data.field}
              />
            );
          }}
        </Field>

        <FovFieldError name='contentName' />
      </Fragment>
    );
  };

  const renderCategorySelect = form => {
    return (
      <Fragment>
        Category*
        <VerticalSpacer size={10} />

        <Field type='select' name='categoryName'>
          {data => (
            <FovSelect
              value={data.field.value}
              placeholder={data.field.value}
              disabled={_.isEmpty(rpcCategories)}
              onChange={(e, newVal) => {
                form.setFieldValue(data.field.name, newVal);
              }}
            >
              {categories}
            </FovSelect>
          )}
        </Field>

        <FovFieldError name='categoryName' />
      </Fragment>
    );
  };

  const renderTags = form => {
    return (
      <Fragment>
        Tags*
        <VerticalSpacer size={10} />

        <TagsContainer theme={theme} $selectedTheme={selectedTheme}>
          <Field type='input' name='description'>
            {data => (
              <Tags
                values={_.isEmpty(data.field.value) ? [] : data.field.value.split(',')}
                onChange={(e, tags) => {
                  form.setFieldValue(data.field.name, tags);
                }}
              />
            )}
          </Field>
        </TagsContainer>

        <FovFieldError name='description' />
      </Fragment>
    );
  };

  const renderHeightFields = form => {
    if (isImageProvided) {
      const placeholder = data => (
        units.find(unit => unit.factor === +data.field.value)?.label
      );

      return (
        <HeightContainer>
          <Label
            name={`Label_Height${Math.random()}`}
          >
            Height*
            <VerticalSpacer size={10} />

            <Field type='number' name='height'>
              {data => (
                <FovInput
                  name={data.field.name}
                  value={data.field.value}
                  {...data.field}
                />
              )}
            </Field>

            <FovFieldError name='height' />
          </Label>

          <Label
            name={`Label_Unit${Math.random()}`}
          >
            Unit
            <VerticalSpacer size={10} />

            <Field type='select' name='heightFactor'>
              {data => (
                <FovSelect
                  value={data.field.value}
                  placeholder={placeholder(data)}
                  onChange={(e, newVal) => {
                    form.setFieldValue(data.field.name, newVal);
                  }}
                >
                  {unitOptions()}
                </FovSelect>
              )}
            </Field>

            <FovFieldError name='heightFactor' />
          </Label>
        </HeightContainer>
      );
    }
  };

  const optimizationSettings = () => {
    if (useModelOptimization) {
      return (
        <OptimizationControlContainer>
          Estimated mesh faces count: &nbsp;
          {Math.round(estimatedFacesCount)?.toLocaleString()}

          <LabeledSliderContainer>
            <FovSlider
              label='Amount'
              defaultValue={optimizationAmount}
              min={0.01}
              max={0.99}
              onChange={value => setOptimizationAmount(value)}
            />
          </LabeledSliderContainer>
        </OptimizationControlContainer>
      );
    }
  };

  const renderOptimization = () => {
    return (
      <Fragment>
        <OptimizationTogglesContainer>
          <ToggleContainer>
            Use Compact RPC

            <FovToggle
              checked={compactRPCEnabled}
              disabled={useModelOptimization}
              callback={handleCompactRPCChange}
            />
          </ToggleContainer>

          <VerticalSpacer size={10} />

          <ToggleContainer>
            Use Model Optimization

            <FovToggle
              checked={useModelOptimization}
              disabled={compactRPCEnabled}
              callback={handleMeshOptimizationChange}
            />
          </ToggleContainer>

          {optimizationSettings()}

          <VerticalSpacer size={25} />
        </OptimizationTogglesContainer>
      </Fragment>
    );
  };

  const renderDebugToggle = () => {
    return (
      <ProtectedContent>
        <FovDivider />
        <VerticalSpacer size={25} />

        <ToggleContainer>
          Debug Package

          <FovToggle
            checked={debugState}
            name='debugToggle'
            callback={() => setDebugState(!debugState)}
          />
        </ToggleContainer>

        <VerticalSpacer size={25} />
      </ProtectedContent>
    );
  };

  return (
    <FovModal
      title={
        <div>
          Create your RPC | &nbsp;
          <Link
            to="/documentation/create-from-file"
            rel="noreferrer"
            target="_blank"
          >
            FOVEA upload guidelines
          </Link>
        </div>
      }
      visible={isVisible}
      onClose={handleClose}
    >
      <Formik
        initialValues={defaults}
        validationSchema={schema}
        enableReinitialization={true}
        onSubmit={(values, handlers) => {
          const { setSubmitting } = handlers;
          handleSubmit({ values, setSubmitting });
        }}
      >
        {form => (
          <Fragment>
            {isModelForSaving
              ? <FovUploader
                  form={form}
                  formDefaults={defaults}
                  updateNameField={true}
                  title='Model Upload*'
                  instructions='Click or drag files here.'
                  supportedExtensions={SUPPORTED_EXTENSIONS}
                  setIsImageProvided={setIsImageProvided}
                />
              : renderOptimization()
            }

            {renderNameField()}
            {renderCategorySelect(form)}
            {renderTags(form)}
            {renderHeightFields(form)}
            {renderDebugToggle()}

            {creditBalance === 0 && (
              <strong>
                <Link
                  to="//archvision.com/fovea/#pricing"
                  rel="noreferrer"
                  target="_blank"
                >
                  Add more credits...
                </Link>
              </strong>
            )}

            <VerticalSpacer size={25} />

            <FovButton
              type='submit'
              disabled={creditBalance === 0 || form.isSubmitting}
              onClick={form.handleSubmit}
            >
              {form.isSubmitting
                ? <IndeterminateProgress $color={darkGrey} />
                : 'Create'
              }
            </FovButton>
          </Fragment>
        )}
      </Formik>
    </FovModal>
  );
};

export default withTheme(RPCForm);
