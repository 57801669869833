import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DownOutlined } from 'components';
import { getColor } from 'utils';
import styled, { css, withTheme } from 'styled-components';
import constants from 'constants/index';
import _ from 'lodash';

const light = constants.THEME.LIGHT;

const SelectContainer = styled('div')`
  position: relative;
  width: 100%;
  min-height: 2.69625rem;
`;

const Select = styled('select')`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 100%;
  appearance: none;
  border-radius: 0.25rem;
  background: transparent;
  cursor: pointer;
  color: ${props => props.$color || getColor(props, 'onPrimary')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => getColor(props, 'lightGrey')}
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0.1rem 0.1rem ${props => getColor(props, 'accent')};
  }
`;

const ArrowContainer = styled('div')`
  position: absolute;
  top: 0.65rem;
  right: 0.5rem;
  pointer-events: none;
`;

const FilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const OptionsContainer = styled('div')`
  position: absolute;
  ${props => props.$position === 'top' ? css`bottom: 2.745rem;` : css`top: 2.745rem;`}
  z-index: 2;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'white' : 'darkGrey');
  }};
  box-shadow: 0 0.1rem 0.25rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'darkGrey' : 'black');
  }};
  width: 100%;
  max-height: 16.5rem;
  overflow-y: auto;
  border-radius: ${props => {
    if (props.$position === 'top') {
      return '0.25rem 0.25rem 0 0';
    }

    return '0 0 0.25rem 0.25rem';
  }};

  option {
    width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    background-color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      return getColor(props, lightTheme ? 'white' : 'darkGrey');
    }};

    &:hover {
      cursor: pointer;
      background-color: ${props => {
        const lightTheme = props.$selectedTheme === light;
        return getColor(props, lightTheme ? 'lightGrey' : 'mediumGrey');
      }};

      &:disabled {
        background-color: ${props => getColor(props, 'transparent')};
        cursor: default;
      }
    }
  }
`;

const FilterInput = styled('input')`
  padding: 0.5rem 1rem;
  width: 100%;
  min-height: 2.5rem;
  min-width: 3.25rem;
  border-radius: ${props => {
    if (props.$position === 'top') {
      return '0 0 0.25rem 0.25rem';
    }

    return '0.25rem 0.25rem 0 0';
  }};
  background: transparent;
  color: ${props => getColor(props, 'onPrimary')};
  border: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};

  &:focus-visible {
    outline: none;
  }
`;

const FovSelect = withTheme(props => {
  const { theme, children, placeholder, position, color, onOpen, ...rest } = props;
  const [ useInput, setUseInput ] = useState(false);
  const [ inputVal, setInputVal ] = useState('');
  const [ filteredChildren, setFilteredChildren ] = useState(children || []);
  const userDataStore = useSelector(state => state.userData);
  const selectedTheme = userDataStore.userPreferences.theme;
  const categoryRef = useRef();
  const optionsRef = useRef();

  const filterOptions = () => {
    const filter = source => {
      return source?.filter?.(option => {
        if (option.props?.children || option.props?.value) {
          const { value, children } = option.props;
          const match = (children || value).toLowerCase?.().includes(inputVal.toLowerCase());

          if (match) {
            return option;
          }
        }
      }) || [];
    };

    const isGroup = _.isArray(children?.[0]);
    const userFilter = isGroup
      ? children?.map(optionGroup => filter(optionGroup))
      : filter(children);

    setFilteredChildren(_.isEmpty(userFilter) ? children : userFilter);
  };

  useEffect(() => {
    if (useInput) {
      categoryRef.current?.focus();
    }

    filterOptions();
  }, [ categoryRef.current, useInput, inputVal ]);

  useEffect(() => {
    setFilteredChildren(children || []);
  }, [ useInput ]);

  const hideOptions = () => {
    setInputVal('');
    setUseInput(false);
  };

  const changeAndClearInput = (e, directClick) => {
    const results = filteredChildren?.filter?.(item => !_.isEmpty(item) && item).flat();

    if (_.isEmpty(inputVal.trim()) || _.isEmpty(results)) {
      hideOptions(true);
    }

    if (results?.length === 1 || directClick) {
      const value = directClick ? e.target.value : results?.[0].props.value;
      rest.onChange?.(e, value);
      hideOptions();
    }

    if (!_.isEmpty(inputVal.trim()) && results?.length > 1) {
      optionsRef.current.children.click?.();
      hideOptions(true);
    }
  };

  return (
    <SelectContainer>
      <FilterContainer>
        {useInput && (
          <>
            <FilterInput
              name={`filterInput${Math.random()}`}
              theme={theme}
              $selectedTheme={selectedTheme}
              $position={position}
              ref={categoryRef}
              type='text'
              value={inputVal}
              placeholder={placeholder || 'Filter options'}
              onChange={e => setInputVal(e.currentTarget.value)}
              onBlur={changeAndClearInput}
              onKeyPress={e => e.key === 'Enter' && changeAndClearInput(e)}
            />

            <OptionsContainer
              ref={optionsRef}
              theme={theme}
              $selectedTheme={selectedTheme}
              $position={position}
              onMouseDown={e => e.preventDefault()}
              onClick={e => changeAndClearInput(e, true)}
            >
              {filteredChildren}
            </OptionsContainer>
          </>
        )}

        {!useInput && (
          <Select
          theme={theme}
          $selectedTheme={selectedTheme}
          onFocus={() => {
            setUseInput(true);
            onOpen?.();
          }}
          $color={color}
          {...rest}
          >
            {children}
          </Select>
        )}
      </FilterContainer>

      <ArrowContainer>
        <DownOutlined width={16} height={16} color={color} />
      </ArrowContainer>
    </SelectContainer>
  );
});

export { FovSelect };