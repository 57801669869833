import JSZip from 'jszip';
import { MESSAGE_TYPES } from 'components/notifications';
import { addMessage, clearMessages } from './app';
import { saveAs } from 'file-saver';
import { keyValueMirror } from 'store/store-functions';
import { RPC_TYPES, exists } from 'utils';
import axios from 'axios';

// TODO: Move this to constants or somewhere else when refactor is complete.
export const Proxies = {
  billboard: 'Billboard',
  boundingBox: 'Bounding Box',
  simpleMesh: 'Simplified Mesh',
  customMesh: 'Custom Mesh',
};

export const actions = keyValueMirror(
  'GET_USER_CREATOR_JOBS',
  'GET_RPC_CATEGORIES',
  'DOWNLOAD_MODEL',
  'DELETE_MODEL',
    'REPROCESS_MODEL',
  'SET_MODEL_LOADED',
  'SET_HIGH_INSTANCE_MESH_COUNT',
  'SET_ENGINE_STATE',
  'SET_PUBLISH_NAME',
  'CLEAR_PUBLISH_NAME',
  'GET_ALL_SAVE_DATA',
  'GET_MODEL_SAVE_DATA',
  'SET_MODEL_SAVE_DATA',
);

const { VITE_API_HOST: apiHost, VITE_API_DEV_HOST } = process.env;
const useLocalHost = false;
const apiDevHost = !useLocalHost ? VITE_API_DEV_HOST : 'http://localhost:8080';

const MULTIPLE_FILES_MODEL_EXTENSIONS = [ 'rpc', 'fbx', 'obj', 'dae', 'gltf', 'glb', 'usd', 'usdz' ];

const formatObjData = (categories, selectableKeys) => {
  return categories.filter(category => category.type === 'model')
    .map(category => {
      return {
        title: category.name,
        value: category.name,
        selectable: selectableKeys?.includes(category.name),
        children: category.subcategories.map(subcategory => {
          return {
            title: subcategory.name,
            value: subcategory.name,
          };
        }),
      };
    });
};

export const getRpcCategories = token => async dispatch => {
  try {
    const url = `${apiDevHost}/metadata`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Categories not found.');
    }

    const data = await res.json();
    const payload = { payload: formatObjData(data.categories, [ 'People', 'Other' ]) };
    const action = { type: actions.GET_RPC_CATEGORIES, ...payload };

    dispatch(action);
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  }
};

export const downloadModel = args => async dispatch => {
  const { rpcGuid, format, onComplete, token, download, saveEnabled, showModal } = args;

  try {
    const onModelsPage = window.location.pathname.includes('/models/');
    const preserveOriginalData = true;

    const messageConfig = currentStep => ({
      type: MESSAGE_TYPES.loading,
      showModal: exists(showModal) ? showModal : true,
      currentStep,
      totalSteps: saveEnabled && onModelsPage ? 4 : 2,
      closeButton: false,
    });

    const url = `${apiDevHost}/models/${rpcGuid}/download/${format}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    if (onModelsPage && saveEnabled) {
      dispatch(
        addMessage([ {
          message: 'Checking data...',
          config: messageConfig(1),
        } ]),
      );

      await dispatch(getModelSaveData(token, rpcGuid, preserveOriginalData));
      dispatch(clearMessages());
    }

    if (!download) {
      dispatch(
        addMessage([ {
          message: 'Linking to model...',
          config: messageConfig(saveEnabled && onModelsPage ? 2 : 1),
        } ]),
      );
    }

    const res = await fetch(url, options);

    if (res.status === 404) {
      setTimeout(() => window.location.href = window.location.origin, 4000);
      dispatch(clearMessages());
      dispatch(
        addMessage([ {
          message: 'Unable to find model. Redirecting...',
          config: {
            type: MESSAGE_TYPES.error,
            showModal: true,
            closeButton: false,
          },
        } ]),
      );

      return;
    }

    if (!res.ok) {
      dispatch(clearMessages());
      window.location.href = window.location.origin;
      dispatch(
        addMessage([ {
          message: 'Unable to load model.',
          config: { type: MESSAGE_TYPES.error },
        } ]),
      );

      return;
    }

    const data = await res.json();

    if (download) {
      return window.location.href = data.url;
    }

    return data.url;
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

    dispatch(clearMessages());
    dispatch(addMessage([ { message, config } ]));
  } finally {
    onComplete?.();
  }
};

export const deleteModel = (job, token) => async dispatch => {
  try {
    const complete = job.status === 'COMPLETED';
    const id = complete ? job.rpc_guid : job.job_guid;
    const url = complete ? `${apiDevHost}/models/${id}` : `${apiHost}/creator/v1/integration/job/${id}`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Job ID not found.');
    }

    const data = await res.json();
    const action = { type: actions.DELETE_MODEL, payload: data };

    dispatch(action);

    if (complete) {
      dispatch(deleteSaveData(token, job.rpc_guid));
    }

    dispatch(
      addMessage([ {
        message: `${job.title || 'selected model'} was deleted`,
        config: { type: MESSAGE_TYPES.deletion, timer: 4 },
      } ]),
    );
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  }
};

export const reprocessModel = args => async dispatch => {
    try {
        const { job, token, handleSockets } = args;
        const id = job.job_guid;
        const url = `${apiHost}/creator/v1/integration/job/${id}`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };

        const res = await fetch(url, options);
        if (res.status === 404) {
            throw new Error('Job Id not found');
        }

        const data = await res.json();
        const action = { type: actions.REPROCESS_MODEL, payload: data };
        dispatch(action);
        handleSockets?.();

        dispatch(
            addMessage([ {
                message: `${job.title || 'selected model'} was sent for reprocessing.`,
                config: { type: MESSAGE_TYPES.success, timer: 4 },
            } ]),
        );
    } catch (e) {
        const message = e.message;
        const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
        dispatch(addMessage([ { message, config } ]));
    }

};

export const preparePayloadAndJobType = async payload => {
  const zip = new JSZip();
  let job_type;

  const handleZipFiles = async payloadFile => {
    const payloadData = await JSZip.loadAsync(payloadFile);
    const promisesArray = [];

    payloadData?.forEach((relativePath, zipEntry) => {
      const unpackedFileExtension = zipEntry?.name.split('.').pop()?.toLowerCase();
      const extensionIndex = MULTIPLE_FILES_MODEL_EXTENSIONS.indexOf(unpackedFileExtension);

      if (extensionIndex !== -1) {
        if (job_type) throw Error('Multiple model files provided');

        job_type = RPC_TYPES?.[unpackedFileExtension];

        promisesArray.push(
          zipEntry?.async('blob').then(file => {
            zip?.file(`model.${unpackedFileExtension}`, file);
          }),
        );

        return;
      }

      promisesArray.push(
        zipEntry?.async('blob').then(file => {
          zip?.file(zipEntry?.name.split('/').pop(), file);
        }),
      );
    });

    await Promise.all(promisesArray);
  };

  const handleSingleFile = file => {
    const fileExtension = file?.name.split('.').pop()?.toLowerCase();
    const extensionIndex = MULTIPLE_FILES_MODEL_EXTENSIONS.indexOf(fileExtension);

    if (extensionIndex !== -1 || fileExtension === 'png') {
      if (job_type) throw Error('Multiple model files provided');
      job_type = RPC_TYPES?.[fileExtension];
      zip?.file(`model.${fileExtension}`, file);
    }
  };

  const handleMultipleFiles = files => {
    Array.from(files)?.forEach(file => {
      const fileExtension = file?.name.split('.').pop()?.toLowerCase();
      const extensionIndex = MULTIPLE_FILES_MODEL_EXTENSIONS.indexOf(fileExtension);

      if (extensionIndex !== -1) {
        if (job_type) throw Error('Multiple model files provided');
        job_type = RPC_TYPES?.[fileExtension];
        zip?.file(`model.${fileExtension}`, file);
        return;
      }

      zip?.file(file?.name, file);
    });
  };

  zip?.file('metadata.json', JSON.stringify({ ...payload }));
  zip?.file('properties.json', JSON.stringify({ proxyMesh: Proxies?.billboard }));

  if (payload?.files?.length === 1) {
    const [ payloadFile ] = payload?.files || [];
    const fileExtension = payloadFile?.name.split('.').pop()?.toLowerCase();

    if (fileExtension === 'zip') {
      // TODO: All supporting texture files need to be placed in a "textures" folder before zipping.
      // Should call handleMultipleFiles after this function below.
      await handleZipFiles(payloadFile);
      return { job_type, zip };
    }

    handleSingleFile(payloadFile);
    return { job_type, zip };
  }

  handleMultipleFiles(payload?.files);
  return { job_type, zip };
};

export const createRpc = args => async dispatch => {
  const { token, userId, payload, debugState, onComplete, handleSockets, userEmail } = args;

  try {
    let { job_type, zip } = payload;

    if (!job_type || !zip) {
      const storeBasedPayload = await preparePayloadAndJobType(payload);
      job_type = storeBasedPayload.job_type;
      zip = storeBasedPayload.zip;
    }

    if (!job_type) {
      throw Error('Model file wasn`t provided');
    }

    const url = `${apiDevHost}/models`;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        jobType: job_type,
        userId,
        requestingApplication: 'FOVEA',
        notificationEmail: userEmail,
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    dispatch(clearMessages());
    dispatch(
      addMessage([ {
        message: 'Creating job…',
        config: { type: MESSAGE_TYPES.loading, showModal: true },
      } ]),
    );

    const res = await fetch(url, options);

    if (res.status === 404) {
      throw new Error('Jobs not found.');
    }

    const data = await res.json();
    const file = await zip.generateAsync({ type: 'blob' });

    const onProgressEvent = e => {
      const loaded = e.loaded;
      const total = e.total || loaded;

      const progress = (bytes, totalBytes) => {
        const conversionUnit = 1024;
        const inKB = bytes / conversionUnit;
        const inMB = inKB / conversionUnit;
        const inGB = inMB / conversionUnit;
        const isGB = inMB >= conversionUnit;
        const unit = isGB ? inGB : inMB;
        const label = isGB ? 'GB' : 'MB';
        const total = parseFloat(totalBytes || 0);
        const stringOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        const percent = `${(Math.floor((bytes / total) * 100))}%`;
        const log = `${unit?.toLocaleString('en-US', stringOptions)} ${label}`;

        return {
          size: log,
          percent,
        };
      };

      dispatch(clearMessages());
      dispatch(
        addMessage([ {
          message: `Uploading model... ${progress(loaded, total).percent}`,
          config: {
            type: MESSAGE_TYPES.loading,
            showModal: true,
            closeButton: false,
            bytesLoaded: loaded,
            bytesTotal: total,
          },
        } ]),
      );
    };

    const fileOptions = {
      onUploadProgress: e => onProgressEvent(e),
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    };

    await axios.put(data.putUrl, file, fileOptions);

    dispatch(clearMessages());
    dispatch(
      addMessage([ {
        message: 'RPC published.',
        config: {
          type: MESSAGE_TYPES.success,
          timer: 2.5,
          showModal: true,
        },
      } ]),
    );

    if (debugState) {
      saveAs(file, 'RPC.zip');
    }

    handleSockets?.();
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };

    dispatch(clearMessages());
    dispatch(addMessage([ { message, config } ]));
  } finally {
    onComplete?.();
  }
};

export const setModelLoaded = payload => dispatch => {
  const action = { type: actions.SET_MODEL_LOADED, payload };
  dispatch(action);
};

export const setEngineState = payload => dispatch => {
  const action = { type: actions.SET_ENGINE_STATE, payload };
  dispatch(action);
};

export const setPublishName = publishName => ({
  type: actions.SET_PUBLISH_NAME,
  payload: publishName,
});

export const clearPublishName = () => ({
  type: actions.CLEAR_PUBLISH_NAME,
  payload: '',
});

export const getAllSaveData = token => async dispatch => {
  console.log('Fetching save data for all models...');

  try {
    const url = `${apiHost}/creator/v1/integration/user/saves`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data?.message || 'Unable to fetch saved data.');
    }

    const action = { type: actions.GET_ALL_SAVE_DATA, payload: data.data };

    dispatch(action);
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  }
};

export const getModelSaveData = (token, guid, preserveOriginalData) => async dispatch => {
  console.log('Fetching save data for this model...');

  try {
    const url = `${apiHost}/creator/v1/integration/rpc/${guid}/save`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data?.message || 'Unable to fetch saved data.');
    }

    const action = {
      type: actions.GET_MODEL_SAVE_DATA,
      payload: data.data,
      meta: { preserveOriginalData },
    };

    dispatch(action);
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  }
};

export const setModelSaveData = args => async dispatch => {
  const {
    token,
    guid,
    payload,
    skipServerUpdate,
    updateOriginalData,
    onComplete,
  } = args;

  try {
    let action = {
      type: actions.SET_MODEL_SAVE_DATA,
      payload,
      meta: { updateOriginalData },
    };

    if (skipServerUpdate) {
      return dispatch(action);
    }

    const url = `${apiHost}/creator/v1/integration/rpc/${guid}/save`;
    const options = {
      method: 'POST',
      body: JSON.stringify({ serialized_data: payload }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data?.message || 'Unable to save changes.');
    }

    action = { ...action, payload };

    dispatch(action);
    dispatch(
      addMessage([ {
        message: 'Changes saved.',
        config: { type: MESSAGE_TYPES.success, timer: 2.5 },
      } ]),
    );

    onComplete?.();
  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  }
};

export const deleteSaveData = (token, guid) => async dispatch => {
  console.log('Deleting save data for this model...');

  try {
    const url = `${apiHost}/creator/v1/integration/rpc/${guid}/save`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    };

    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data?.message || 'Unable to delete save changes.');
    }

  } catch (e) {
    const message = e.message;
    const config = { type: MESSAGE_TYPES.error, timer: 2.5 };
    dispatch(addMessage([ { message, config } ]));
  }
};

export const setHighInstanceMeshCount = payload => ({
  type: actions.SET_HIGH_INSTANCE_MESH_COUNT,
  payload,
});
