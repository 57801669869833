import { Link } from 'react-router-dom';
import { FovTypography } from 'components/typography';
import { getColor } from 'utils';
import styled, { css } from 'styled-components';
import constants from 'constants/index';

export const AppHeaderContainer = styled('header')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  min-height: ${constants.HEADER_HEIGHT / 16}rem;
  background-color: ${props => getColor(props, 'primary')};
  border-bottom: 0.0625rem solid ${props => getColor(props, 'mediumGrey')};
  justify-content: space-between;
`;

export const LogoContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  width: 13.125rem;
  height: 100%;
`;

export const ThemeSwitchContainer = styled('div')`
  padding: 0 1.875rem;
`;

export const AppOptionsContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const MenuLink = styled(Link)`
  position: relative;
  color: ${props => getColor(props, 'onPrimary')};
`;

export const BasicLink = styled('div')`
  position: relative;
  color: ${props => getColor(props, 'onPrimary')};
`;

export const ClickableItem = styled('div')`
  cursor: pointer;
`;

export const Menu = styled(FovTypography)`
  display: flex;
  position: relative;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.035rem;
  color: ${props => {
    const themeColor = props.active ? 'accent' : 'onPrimary';
    return getColor(props, themeColor);
  }};

  &:hover {
    color: ${props => getColor(props, 'accent')};

    svg {
      color: ${props => getColor(props, 'accent')};
    }
  }

`;

export const CreateCaretDownContainer = styled('div')`
  transform: translateY(${props => props.active ? '0.25rem' : 0});
  transition: transform 0.2s ease;
`;

export const MenuItem = styled(FovTypography)`
  cursor: pointer;

  ${props => props.topLevel && css`font-weight: 600;`}

  &:hover {
    color: ${props => getColor(props, 'accent')};

    svg {
      color: ${props => getColor(props, 'accent')};
    }
  }
`;

export const NormalMenuItem = styled(MenuItem)`
  font-size: 0.9rem;
`;

export const MenuContainer = styled('div')`
  display: inline-flex;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`;

export const ProfileMenuContainer = styled(FovTypography)`
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, auto);
  letter-spacing: 0.045rem;
  color: ${props => getColor(props, 'onPrimary')}
`;

export const Profile = styled('div')`
  position: relative;
`;

export const Credits = styled('div')`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 1.55rem;
  right: 0;
  font-size: 0.85rem;
  cursor: pointer;
`;

export const InfoAndSupport = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  padding: 0.15rem;
  background-color: ${props => getColor(props, 'onPrimary')};
  color: ${props => getColor(props, 'primary')};
  font-weight: bold;
  cursor: ${props => props.noCursor ? 'default' : 'pointer'};
`;

export const Ellipses = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  position: absolute;
  top: 0.125rem;
`;

export const HelpIcon = styled('div')`
   display: flex;
  align-items: center;  /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 20px;
  font-weight: bold;
  width: 24px;  /* Adjust size as needed */
  height: 24px;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
`;