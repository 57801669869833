import React, { useEffect, useState, useRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  DocNote,
  TagsOutlined,
  H4,
  HorizontalSpacer,
  VerticalSpacer,
  FovSkeletonLoader,
  SearchInputField,
} from 'components';
import {
  TagsContainer,
  ClearAll,
  Tag,
  FilterSection,
  FilterTitle,
  TitleGroup,
  NoSelectionMessage,
} from './panelStyles';
import { withTheme } from 'styled-components';
import { paramsToObject, paramsFromURL } from 'utils/paramHelpers';
import { decode } from 'utils';
import _ from 'lodash';
import { ModelContext } from 'pages/Home/ModelContext';

const Tags = withTheme(props => {
  const { theme } = props;
  const { getModels, workingData } = useContext(ModelContext);

  const userDataStore = useSelector(state => state.userData);
  const tags = workingData?.tags;
  const tagSearchRef = useRef();

  const [ filteredTags, setFilteredTags ] = useState(null);
  const [ searchParams, setSearchParams ] = useSearchParams();

  useEffect(() => {
    if (!filteredTags && tags) {
      setFilteredTags(tags);
    }
  }, [ tags, filteredTags, setFilteredTags ]);

  useEffect(() => {
    if (!_.isEmpty(tagSearchRef?.current?.value)) {
      tagSearchRef.current.value = '';
    }

    setFilteredTags(tags);
  }, [ tags ]);

  const userPreferences = userDataStore.userPreferences;
  const filterPanel = userPreferences?.filterPanel;
  const selectedTheme = userDataStore.userPreferences.theme;
  const params = paramsToObject(decode(paramsFromURL()));
  const activeTags = typeof params?.tags === 'string' ? [ params.tags ] : params.tags || [];

  const buildTags = () => {
    const tagList = filteredTags
      ?.toSorted((a,b) => {
        if (activeTags.includes(a) && !activeTags.includes(b)) return -1; // a comes first
        if (!activeTags.includes(a) && activeTags.includes(b)) return 1; // b comes first
        return 0;
      })
      ?.map((tag, index) => {
        const isActive = activeTags?.includes(tag);

        return (
          <Tag
            key={index}
            theme={theme}
            $selectedTheme={selectedTheme}
            active={isActive}
            onClick={() => {
              const withNewTag = [ ...activeTags, tag ];

              if (isActive) {
                const withoutTag = activeTags?.filter?.(activeTag => activeTag != tag);
                setSearchParams({ ...params, tags: withoutTag, page: 1 });
                getModels();
                return;
              }


              setSearchParams({
                ...params,
                tags: withNewTag,
                page: 1,
              });

              getModels();
            }}
          >
            {tag}
          </Tag>
        );
      });

    return !_.isEmpty(tagList) ? tagList : (
      <NoSelectionMessage theme={theme} $selectedTheme={selectedTheme}>
        <p>No tags found.</p>
      </NoSelectionMessage>
    );
  };

  return (
    <FilterSection collapsed={filterPanel}>
      <FilterTitle>
        <TitleGroup>
          <TagsOutlined
            isTitleIcon={true}
            flipIcon={true}
            $selectedTheme={selectedTheme}
          />
          <HorizontalSpacer size={6} />
          <H4>Tags</H4>

          <DocNote path={[ 'filterMyFiles', 'tags' ]} top={-1} left={87} />
        </TitleGroup>

        {activeTags.length > 0 && (
          <ClearAll
            theme={theme}
            $selectedTheme={selectedTheme}
            onClick={() => {
              searchParams.delete('tags');
              searchParams.set('page', 1);

              setSearchParams(searchParams);
              getModels();
            }}
          >
            Clear all
          </ClearAll>
        )}
      </FilterTitle>

      <VerticalSpacer size={18} />

      <FovSkeletonLoader type='tags' resource={tags}>
        <SearchInputField
          ref={tagSearchRef}
          filter={true}
          placeholder='Filter tags'
          onChange={e => {
            const newValue = e.target.value;

            const userFilterStrings = newValue
              .split(',')
              .map(tag => _.toLower(tag).trim())
              .filter(item => !_.isEmpty(item));

            const filtered = tags.filter(tag => (
              userFilterStrings.some(userFilterString => _.toLower(tag).includes(userFilterString))
            ));

            setFilteredTags(_.isEmpty(newValue) ? tags : filtered);
          }}
        />

        <VerticalSpacer size={16} />

        <TagsContainer>
          {buildTags()}
        </TagsContainer>
      </FovSkeletonLoader>
    </FilterSection>
  );
});

export { Tags };